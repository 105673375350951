import axios, { AxiosError } from 'axios'
import AuthService from '~services/auth'
import { Modal } from 'antd'

const baseRequest = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
})

baseRequest.interceptors.request.use((config) => {
    const token = AuthService.getToken()

    return {
        ...config,
        headers: {
            ...config.headers,
            ...(token && { Authorization: `Bearer ${token}` }),
        },
    }
})

baseRequest.interceptors.response.use(
    (res) => res.data,
    (err: AxiosError | any) => {
        if (err.response) {
            if (err.response.status === 401) {
                if (window.location.pathname !== "/") {
                    Modal.error({
                        title: 'System Error',
                        content: err.response.data.message as string,
                        onOk() {
                            window.location.href = "/logout"
                        },
                    });
                }
            }
            return Promise.reject(err.response.data)
        }
        return Promise.reject({
            message: 'The server cannot be connected at this time.',
        })
    }
)

export default baseRequest
