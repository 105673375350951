import useSWR from 'swr'
import ServiceAPI from '~services/services'

export const useFetchPercentageHolds = () => {
    const { data, mutate, error } = useSWR([`/percentage-hold`], () => ServiceAPI.getPercentageHolds());

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchPercentageHolds
