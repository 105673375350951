import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '~store/index'
import { Modal, Descriptions, Space } from 'antd'
import Button from "antd-button-color"
import ChangePasswordAccount from './ChangePasswordAccount'

interface ModalProps {
    close: () => void
    isOpen: boolean
}

const Profile: FC<ModalProps> = ({ isOpen, close }) => {
    const authState = useSelector((state: ApplicationState) => state.auth)
    const [dataUser, setDataUser] = useState<any>([])

    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState<boolean>(false);
    const toggleModalChangePassword = () => setIsChangePasswordOpen(!isChangePasswordOpen)

    const widthModal = (widthScreen: number) => {
        if (widthScreen >= 1920) {
            return global.window.innerWidth * .3
        }

        if (widthScreen >= 1200) {
            return global.window.innerWidth * .5
        }

        return widthScreen
    }

    useEffect(() => {
        if (isOpen && authState.data.user) {
            authState.data.username === "supermaster" ? setDataUser(authState.data.user) : setDataUser(authState.data.subUser)
        }
    }, [authState, isOpen])

    return (
        <Modal
            title={`Profile`}
            open={isOpen}
            onCancel={close}
            centered
            width={widthModal(global.window.innerWidth)}
            footer={[
                <Button key="change-password"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={toggleModalChangePassword}>
                    <i className="fa-solid fa-key me-1"></i> Change Password</Button>,
                <Button key="close" onClick={close} size="large">Close</Button>,
            ]}>
            {dataUser && (
                <>
                    <Space align="start" style={{ display: 'flex' }}>
                        <Descriptions title="Profile Information" column={1}>
                            {authState.data.username !== "supermaster" &&
                                <Descriptions.Item label="Name">{dataUser.name || "-"}</Descriptions.Item>
                            }
                            <Descriptions.Item label="Username">{dataUser.username}</Descriptions.Item>
                            <Descriptions.Item label="Role">{authState.data.username === "supermaster" ? dataUser.role : "ADMIN"}</Descriptions.Item>
                        </Descriptions>
                        {authState.data.username !== "supermaster" &&
                            <Descriptions title="Contact Information" column={1}>
                                <Descriptions.Item label="Phone number">{dataUser?.phone || "-"}</Descriptions.Item>
                            </Descriptions>
                        }
                    </Space>
                    {isChangePasswordOpen &&
                        <ChangePasswordAccount
                            isOpen={isChangePasswordOpen}
                            close={toggleModalChangePassword}
                            username={dataUser?.username}
                        />}
                </>
            )}

        </Modal>
    )
}

export default Profile