import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { requestAuthInitActions } from './auth.actions'
import { AuthInitResponse } from '~types/auth'

export interface State {
	loading: boolean
	error: string

	init: boolean
	data: AuthInitResponse
}

export const defaultState: State = {
	loading: false,
	error: '',

	init: false,
	data: {} as AuthInitResponse
}

export default reducerWithInitialState(defaultState)
	.case(requestAuthInitActions.started, (state, payload) => ({
		...state,
		loading: payload,
		error: '',
	}))
	.case(requestAuthInitActions.done, (state, payload) => ({
		...state,
		loading: false,

		init: true,
		data: payload.result
	}))
	.case(requestAuthInitActions.failed, (state, payload) => ({
		...state,
		loading: false,
		error: payload.error.message,

		init: true,
	}))
	.default((state) => state)
