import useSWR from 'swr'
import ServiceAPI from '~services/services'

export const useFetchGames = () => {
    const { data, mutate, error } = useSWR([`/games`], () => ServiceAPI.getGames());

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchGames
