import React, { FC } from 'react'
import { Empty } from 'antd'

interface EmptyProps {
    description?: string
}
const EmptyComponents: FC<EmptyProps> = ({ description }) => {
    return (
        <div className="empty-data">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description ? description : "No Data"} />
        </div>
    );
}

export default EmptyComponents