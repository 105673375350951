import React, { FC, Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
    Form,
    Table,
    Divider,
    Space,
    Tabs,
    Select,
} from 'antd'
import Button from "antd-button-color"
import to from 'await-to-js'
import {
    PercentageHolds,
} from '~types/provider'
import TitleBar from '~components/Elements/TitleBar'
import Empty from '~components/Elements/Empty'
import Loading from '~components/Elements/Loading'
import EmptyComponents from '~components/Elements/Empty'
import { getProvider, getGamesType } from '~utils/games'
import useFetchPercentageHolds from '~hooks/useFetchPercentageHolds'
import ServiceAPI from '~services/services'
import { ApplicationState, ApplicationProviderState } from '~store/index'
import { useSelector } from 'react-redux'


const SettingPercentage: FC = () => {
    const [form] = Form.useForm();

    const routes = [
        {
            path: '',
            breadcrumbName: 'Providers Management',
        },
        {
            path: '',
            breadcrumbName: 'Setting Percentage',
        },
    ];

    const authState = useSelector((state: ApplicationState) => state.auth)
    const { providers: providerState } = useSelector((state: ApplicationProviderState) => state.providers)

    const permissions = authState.data.permissions
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [userProducts, setUserProducts] = useState<any>([])
    const [productsChecked, setProductsChecked] = useState<any>([])
    const { data, isLoading, mutate } = useFetchPercentageHolds()

    const updateData = async (values: PercentageHolds | any) => {
        const [err, res] = await to(ServiceAPI.updatePercentageHolds(values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res.message)
            setBtnLoading(false)
            mutate()
        }
    }

    const handleFormSubmit = async () => {
        await form.validateFields()
            .then(async (values: PercentageHolds | any) => {
                setBtnLoading(true)
                values.products = await fetchDataProducts(values)
                updateData(values)
            })
            .catch((errorInfo) => {
                setBtnLoading(false)
            });
    }

    const fetchDataProducts = async (values: PercentageHolds) => {
        await Object.keys(productsChecked).forEach((item: any) => {
            let itemProductCheck = productsChecked[item]
            let itemProductMain = {
                enabled: productsChecked[item].enabled,
                givenPT: productsChecked[item].givenPT
            }
            let itemProduct = values.products[item] !== undefined ? values.products[item] : itemProductMain
            itemProductCheck.givenPT = itemProduct.givenPT
            values.products[item] = itemProductCheck
        })
        return values.products
    }

    // const listProducts = (gameType: string) => {
    //     console.log(gameType);
    //     console.log(userProducts);

    //     if (userProducts.length > 0) {
    //         const products = Object.keys(userProducts).map((key: string, index: any) => {
    //             if (!userProducts[key].enabled) {
    //                 return []
    //             }
    //             const labelCheckbox = userProducts[key]
    //             const res = getProvider(labelCheckbox.key, providerState.data)
    //             if (typeof res === "string") {
    //                 return []
    //             }
    //             res.enabled = userProducts[key].enabled
    //             res.givenPT = userProducts[key].ownPT
    //             return res
    //         })
    //         return products.filter((product: any) => product.type === gameType)
    //     }
    // }

    const TabTypeProducts = Object.keys(getGamesType).map((item: string, index: any) => {
        // const productList: any = listProducts(item.toLocaleUpperCase())
        const productList: any = userProducts.filter((product: any) => product.type === item.toLocaleUpperCase())
        const productType = (Object.values(getGamesType)[index]).toLocaleUpperCase()
        const columns = [
            {
                title: 'Provider Name',
                dataIndex: 'name',
                key: `columns-name-${productType}`,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            {getProvider(record.key, providerState.data).name}
                        </Space>
                    )
                }
            },
            {
                title: 'Percentage',
                dataIndex: 'percentage',
                key: `columns-pt-${productType}`,
                width: '200px',
                className: 'text-center',
                render: (text: any, record: any) => {
                    if (permissions && permissions["PERCENTAGE-MANAGEMENT"] === "EDIT") {
                        return (
                            <Form.Item
                                name={['products', `${record.key}`, 'givenPT']}
                                key={`select-givenPT-${record.key}`}>
                                <Select
                                    placeholder="Select Percentage"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.join('').toLowerCase().includes(input.toLowerCase())
                                    }
                                    // disabled={permissions && (permissions["PERCENTAGE-MANAGEMENT"] === "VIEW" || permissions["PERCENTAGE-MANAGEMENT"] === "OFF")}
                                    onSelect={
                                        (value: any) => {
                                            form.setFieldsValue({
                                                'products': {
                                                    [`${record.key}`]: {
                                                        'givenPT': value
                                                    }
                                                }
                                            })
                                        }
                                    }>
                                    {[...Array(101)].map((x, i) =>
                                        <Fragment key={`key-givenPT-${i}`}>
                                            <Select.Option value={i} key={`givenPT-${i}`}>{i.toString()}%</Select.Option>
                                        </Fragment>
                                    )}
                                </Select>
                            </Form.Item>
                        )
                    } else {
                        return `${form.getFieldValue(['products', `${record.key}`, 'givenPT'])}%`
                    }
                }
            },
        ];
        return {
            label: productType,
            key: index,
            children: (
                (productList && productList.length > 0) ? (
                    <Table
                        size={'small'}
                        className="table-products"
                        // rowSelection={rowSelection}
                        rowKey={(record: any) => record.key}
                        key={`table-${productType}`}
                        dataSource={productList}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: true }}
                        bordered />
                ) : <Empty key={`Empty ${productType}`} />
            ),
            disabled: productList && productList.length > 0 ? false : true
        }
    })


    useEffect(() => {
        if (form) {
            form.resetFields();
            let productsObj: object = {}
            let percentageHold: any = providerState !== undefined && providerState.data ?
                Object.entries(providerState.data as object).map(([k, v]) => {
                    // data?.products
                    v.key = k
                    const dataProduct: any = data?.products[v.key] as any
                    form.setFieldsValue({
                        'products': {
                            [`${v.key}`]: {
                                'enabled': dataProduct?.enabled || true,
                                'givenPT': dataProduct?.ownPT || 0
                            }
                        }
                    })
                    Object.assign(productsObj, {
                        [`${v.key}`]: {
                            'enabled': dataProduct?.enabled || true,
                            'givenPT': dataProduct?.ownPT || 0
                        }
                    });
                    return v
                })
                : []
            setProductsChecked(productsObj)
            setUserProducts(percentageHold)

            return () => {
                setProductsChecked([])
                setUserProducts([])
            }
        }
    }, [form, data, providerState])

    return (
        <Fragment>
            <TitleBar title="Setting Percentage" routes={routes} />
            {isLoading ? <Loading /> : (
                providerState && Object.keys(providerState.data).length > 0
                    ? (
                        <>
                            {permissions && permissions["PERCENTAGE-MANAGEMENT"] === "EDIT" && (
                                <div className="row mb-md-3 align-items-md-center">
                                    <div className="mb-3 mb-md-0">
                                        <Button
                                            type="primary"
                                            className="d-block ms-auto"
                                            icon={<i className="fa-solid fa-save me-3"></i>}
                                            disabled={btnLoading}
                                            htmlType="submit"
                                            onClick={handleFormSubmit}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            )}
                            <div className="box-white ant-box-card  mt-3 mt-md-0">
                                <Form
                                    form={form}
                                    name="setting-percentage"
                                    layout="vertical">
                                    <Tabs defaultActiveKey="0" centered items={TabTypeProducts} />
                                    {permissions && permissions["PERCENTAGE-MANAGEMENT"] === "EDIT" && (
                                        <>
                                            <Divider />
                                            <div className="drawer-actions">
                                                <Space>
                                                    <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large" className="text-white me-2"><i className="fa-solid fa-save me-2"></i>Save</Button>
                                                </Space>
                                            </div>
                                        </>
                                    )}
                                </Form>
                            </div >
                        </>
                    ) : (<EmptyComponents />)
            )}
        </Fragment >
    )
}

export default SettingPercentage