import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { useNavigate } from 'react-router-dom'
import { Modal, Button, Typography, Form, Input, Row, Col } from 'antd'
import { toast } from 'react-toastify'
import to from 'await-to-js';
import ServiceAPI from '~services/services'
import {
    NewPasswordParams
} from '~types/users'
import { requestAuthInit } from '~store/auth/auth.actions'
import { ThunkDispatch } from '~types/store'

interface ModalProps {
    close: () => void
    isOpen: boolean

    username: string
    onloadData?: () => void
}

const ChangePasswordAccount: FC<ModalProps> = ({ isOpen, close, username, onloadData }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()


    const updateData = async (values: NewPasswordParams) => {
        const [err, res] = await to(ServiceAPI.setUsersChangeNewPassword(values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
            navigate('/logout')
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: NewPasswordParams) => {
                setBtnLoading(true)
                updateData(values)
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (isOpen) {
            form.resetFields();
        }
    }, [form, isOpen])

    return (
        <Modal
            title={`Change Password`}
            open={isOpen}
            onCancel={close}
            onOk={handleFormSubmit}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .3}
            footer={[
                <Button key="submit" type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i>Save</Button>,
                <Button key="close" onClick={close}>Close</Button>,
            ]}>
            <Typography.Title level={5}>
                Username : {username}
            </Typography.Title>
            <Form
                form={form}
                name="changePassword"
                layout="vertical">
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            name="currentPassword"
                            label="Current Password"
                            rules={[{ required: true, message: 'Please input your current password!' }]}
                            hasFeedback>
                            <Input.Password placeholder="Current Password" inputMode="text" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="newPassword"
                            label="New Password"
                            rules={[{ required: true, message: 'Please input your new password!' }]}
                            hasFeedback>
                            <Input.Password placeholder="New Password" inputMode="text" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="confirmNewPassword"
                            label="Confirm New Password"
                            dependencies={['newPassword']}
                            rules={[
                                { required: true, message: 'Please confirm your new password!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                })
                            ]}
                            hasFeedback>
                            <Input.Password placeholder="Confirm New Password" inputMode="text" />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    )
}

export default ChangePasswordAccount