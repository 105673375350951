import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { requestProviderInitActions } from './provider.actions'

export interface State {
	isLoaded: boolean
	providers?: any
	error: string
}

export const defaultState: State = {
	isLoaded: false,
	providers: undefined,
	error: '',
}



export default reducerWithInitialState(defaultState)
	.case(requestProviderInitActions.started, (state, payload) => ({
		...state,
		isLoaded: payload,
		error: '',
	}))
	.case(requestProviderInitActions.done, (state, payload) => ({
		...state,
		isLoaded: false,
		providers: payload.result
	}))
	.case(requestProviderInitActions.failed, (state, payload) => ({
		...state,
		isLoaded: false,
		error: payload.error.message,
	}))
	.default((state) => state)