import { CommonResponse, CommonResponseUpload } from '~types/api'
import api from '~utils/api'
// import { DateRangeType } from '~types/DatePicker'
import { PaginationParams } from '~types/pagination'
import {
	Users,
	UserRole,
	UsersHistory,
	ViewUsersHistoryData,
	UserEdit,
	SubUserEdit,
	SubUsers,
	MemberAdd,
	AgentAdd,
	SubUserAdd,
	RestrictParams,
	PasswordParams,
	NewPasswordParams
} from '~types/users'
import {
	TransferAdd
} from '~types/transfer'
import {
	AccessLogs,
	PaymentLogs
} from '~types/logs'
import {
	PaymentStatements,
} from '~types/payment'
import {
	AnnouncersMember,
	AddAnnouncersMember,
	AnnouncersAgent,
	AddAnnouncersAgent
} from '~types/announcers'
import {
	Topgraphic,
	AddTopgraphic,
	EditTopgraphic
} from '~types/topgraphic'

import {
	Popup,
	AddPopup
} from '~types/popup'
import { WinLoss, WinLossMember, WinLossParams } from '~types/reports'
import { ActiveSecurity, InitializeSecurity } from '~types/security'
import { Provider, PercentageHolds, IProviderData, IProviderUpdate, IProvider } from '~types/provider'
import { Game, IGamesUpdate, IGamesAdd } from '~types/game'
import { QueryParams } from '../@types/queryParams'


class ServiceAPI {

	getAnnouncerMember(): Promise<AnnouncersMember> {
		return api.get<void, AnnouncersMember>(`/smt/announcer-member`)
	}

	addAnnouncerMember(data: AddAnnouncersMember): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/smt/announcer-member`, data)
	}

	deleteAnnouncerMember(id: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/smt/announcer-member/${id}`)
	}

	getAnnouncerAgent(): Promise<AnnouncersAgent> {
		return api.get<void, AnnouncersAgent>(`/smt/announcer-agent`)
	}

	addAnnouncerAgent(data: AddAnnouncersAgent): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/smt/announcer-agent`, data)
	}

	deleteAnnouncerAgent(id: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/smt/announcer-agent/${id}`)
	}

	uploadImage(data: any): Promise<CommonResponseUpload> {
		return api.post<void, CommonResponseUpload>('/smt/upload', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})
	}

	getTopgraphic(): Promise<Topgraphic> {
		return api.get<void, Topgraphic>(`/smt/topgraphic`)
	}

	addTopgraphic(data: AddTopgraphic): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/smt/topgraphic', data)
	}

	editTopgraphic(data: EditTopgraphic, id: string): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/smt/topgraphic/${id}`, data)
	}

	deleteTopgraphic(id: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/smt/topgraphic/${id}`)
	}

	getPopup(): Promise<Popup> {
		return api.get<void, Popup>(`/smt/popup`)
	}

	addPopup(data: AddPopup): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/smt/popup', data)
	}

	deletePopup(key: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/smt/popup/${key}`)
	}

	get2FASecurity(): Promise<InitializeSecurity> {
		return api.get<void, InitializeSecurity>(`/smt/security`)
	}

	active2FASecurity(data: ActiveSecurity): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/smt/security`, data)
	}

	getSubUser(username: string): Promise<SubUsers> {
		return api.get<void, SubUsers>(`/smt/sub-users/${username}`)
	}

	addSubUser(data: SubUserAdd): Promise<CommonResponse> {
		return api.put<void, CommonResponse>('/smt/sub-users', data)
	}

	updateSubUser(username: string, data: SubUserEdit): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/smt/sub-users/${username}`, data)
	}

	setSubUsersRestrict(username: string, data: RestrictParams): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/smt/sub-users/${username}/restrict`, data)
	}

	setSubUsersChangePassword(username: string, data: PasswordParams): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/smt/sub-users/${username}/password`, data)
	}

	setUsersChangePassword(username: string, data: PasswordParams): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/smt/users/${username}/password`, data)
	}

	setUsersChangeNewPassword(data: NewPasswordParams): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/smt/me/change-password`, data)
	}

	getGames(): Promise<Game> {
		return api.get<void, Game>(`/smt/game`)
	}

	addGames(data: IGamesAdd): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/smt/game`, data)
	}

	editGames(data: IGamesUpdate): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/smt/game`, data)
	}

	deleteGame(id: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/smt/game/${id}`)
	}

	getProviders(): Promise<Provider> {
		return api.get<void, Provider>(`/smt/providers`)
	}

	addProviders(data: IProviderData): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/smt/providers`, data)
	}

	editProviders(data: IProviderUpdate, id: string): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/smt/providers/${id}`, data)
	}

	updateProvidersStatus(data: any, id: string): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/smt/providers/${id}/status`, data)
	}

	getPercentageHolds(): Promise<PercentageHolds> {
		return api.get<void, PercentageHolds>(`/smt/percentage-hold`)
	}

	updatePercentageHolds(data: PercentageHolds): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/smt/percentage-hold`, data)
	}

	getAccessLogs(params: QueryParams): Promise<AccessLogs> {
		return api.get<void, AccessLogs>(`/smt/logs/access-logs`, {
			params: {
				limit: params.limit,
				page: params.page,
				startDate: params?.startDate,
				endDate: params?.endDate,
			},
		})
	}

	getProviderPercentage(): Promise<IProvider> {
		return api.get<void, IProvider>(`/smt/providers/percentage`)
	}

	/////////////////////////

	addMember(data: MemberAdd): Promise<CommonResponse> {
		return api.put<void, CommonResponse>('/smt/members', data)
	}

	addAgent(data: AgentAdd): Promise<CommonResponse> {
		return api.put<void, CommonResponse>('/smt/agents', data)
	}

	getUser(username: string): Promise<Users> {
		return api.get<void, Users>(`/smt/users/${username}`)
	}

	getHistoryEditUser(username: string): Promise<UsersHistory> {
		return api.get<void, UsersHistory>(`/smt/users/${username}/logs`)
	}

	getViewHistoryEditUser(username: string, id: string): Promise<ViewUsersHistoryData> {
		return api.get<void, ViewUsersHistoryData>(`/smt/users/${username}/logs/${id}`)
	}

	updateUser(username: string, data: UserEdit): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/smt/users/${username}`, data)
	}

	getUserChildren(username: string, role: UserRole, params: PaginationParams): Promise<Users> {
		return api.get<void, Users>(`/smt/users/${username}/children`, {
			params: {
				limit: params.limit,
				page: params.page,
				role: role,
			},
		})
	}

	getSubUserList(params: QueryParams): Promise<SubUsers> {
		return api.get<void, SubUsers>(`/smt/sub-users`, {
			params: {
				limit: params.limit,
				page: params.page,
				startDate: params?.startDate,
				endDate: params?.endDate,
				search: params?.search,
			},
		})
	}


	setUsersRestrict(username: string, data: RestrictParams): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/smt/users/${username}/restrict`, data)
	}

	setUsersDeposit(username: string, data: TransferAdd): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/smt/users/${username}/deposit`, data)
	}

	setUsersWithdraw(username: string, data: TransferAdd): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/smt/users/${username}/withdraw`, data)
	}

	// getAccessLogs(params: PaginationParams): Promise<AccessLogs> {
	// 	return api.get<void, AccessLogs>(`/smt/logs/access-logs`, {
	// 		params: {
	// 			limit: params.limit,
	// 			page: params.page,
	// 		},
	// 	})
	// }

	getPaymentLogs(params: PaginationParams): Promise<PaymentLogs> {
		return api.get<void, PaymentLogs>(`/smt/logs/payment-logs`, {
			params: {
				limit: params.limit,
				page: params.page,
			},
		})
	}

	// getFeedbacks(params: FeedbackParams): Promise<Feedback> {
	// 	return api.get<void, Feedback>(`/smt/feedbacks`, {
	// 		params: {
	// 			limit: params.limit,
	// 			page: params.page,
	// 			parent: params?.parent,
	// 			type: params?.type,
	// 		},
	// 	})
	// }

	getReportWinLoss(params: WinLossParams): Promise<WinLoss> {
		return api.get<void, WinLoss>(`/smt/reports/daily-reports`, {
			params: {
				limit: params.limit,
				page: params.page,
				username: params?.username,
				startDate: params?.startDate,
				endDate: params?.endDate,
			},
		})
	}
	getReportWinLossMember(params: WinLossParams): Promise<WinLossMember> {
		return api.get<void, WinLossMember>(`/smt/reports/transactions`, {
			params: {
				limit: params.limit,
				page: params.page,
				username: params?.username,
				startDate: params?.startDate,
				endDate: params?.endDate,
			},
		})
	}

	getPaymentStatement(username: string, params: PaginationParams): Promise<PaymentStatements> {
		return api.get<void, PaymentStatements>(`/smt/payments/statements/${username}`, {
			params: {
				limit: params.limit,
				page: params.page,
			},
		})
	}

	refresh2FA(username: string): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/smt/security/${username}/refreshSecret`)
	}


}

export default new ServiceAPI()
