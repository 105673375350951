export const permissions: any = {
    "SUB-USER-MANAGEMENT": {
        "name": "Sub User Management",
    },
    "PROVIDERS-MANAGEMENT": {
        "name": "Providers Management",
    },
    "PERCENTAGE-MANAGEMENT": {
        "name": "Setting Percentage",
    },
    "GAMES-MANAGEMENT": {
        "name": "Games Management",
    },
    // "AGENTS": {
    //     "name": "Agents Management",
    // },
    "ANNOUNCER-AGENT-MANAGEMENT": {
        "name": "Announcer Agent",
    },
    "ANNOUNCERT-MEMBER-MANAGEMENT": {
        "name": "Announcer Member",
    },
    // "SETTING-MAINTENANCE": {
    //     "name": "Setting Maintenance",
    // },
    "TOP-GRAPHICS-MANAGEMENT": {
        "name": "Setting Topgraphics",
    },
    "POPUP-MANAGEMENT": {
        "name": "Setting Popup",
    },
    "ACCESS-LOGS": {
        "name": "Access Logs",
    },
}


export const permissionRadioOption: any = [
    { label: "Off", value: "OFF" },
    { label: "View", value: "VIEW" },
    { label: "Edit", value: "EDIT" },
]