import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux'
import thunk from 'redux-thunk'
import authReducer, {
	defaultState as authDefaultState,
	State as AuthState,
} from './auth/auth.reducer'

import providerReducer, {
	defaultState as providerDefaultState,
	State as ProviderState,
} from './provider/provider.reducer'

const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__

export interface ApplicationState {
	auth: AuthState
}

export const defaultState: ApplicationState = {
	auth: authDefaultState,
}


export interface ApplicationProviderState {
	providers: ProviderState
}

export const defaultProviderState: ApplicationProviderState = {
	providers: providerDefaultState,
}

const reducers = combineReducers({
	auth: authReducer,
	providers: providerReducer,
})

const enhancers = compose(
	applyMiddleware(thunk),
	process.env.NODE_ENV !== 'production' && reduxDevTools
		? reduxDevTools()
		: (f: any) => f
) as any

export default createStore(reducers, defaultState, enhancers)
