
export const Providers: any = {
    "allbet": {
        "name": "Allbet",
        "type": "LIVE",
        "pt": 93
    },
    "ag": {
        "name": "Asia Gaming",
        "type": "LIVE",
        "pt": 92
    },
    "bg": {
        "name": "Big Gaming",
        "type": "LIVE",
        "pt": 93
    },
    "dg": {
        "name": "Dream Gaming",
        "type": "LIVE",
        "pt": 93
    },
    "ebet": {
        "name": "Ebet",
        "type": "LIVE",
        "pt": 92
    },
    "pp_live": {
        "name": "Pragmatic Play",
        "type": "LIVE",
        "pt": 91
    },
    "pp_slot": {
        "name": "Pragmatic Play",
        "type": "SLOT",
        "pt": 93
    },
    "gclub": {
        "name": "G Club",
        "type": "LIVE",
        "pt": 82
    },
    "sagaming": {
        "name": "Sa Gaming",
        "type": "LIVE",
        "pt": 93
    },
    "sexybcrt": {
        "name": "Sexy Gaming",
        "type": "LIVE",
        "pt": 92
    },
    "prettygaming": {
        "name": "Pretty Gaming",
        "type": "LIVE",
        "pt": 92
    },
    "wmcasino": {
        "name": "WM Casino",
        "type": "LIVE",
        "pt": 92
    },
    "xg": {
        "name": "XG",
        "type": "LIVE",
        "pt": 95
    },
    "betgame": {
        "name": "betgames.tv",
        "type": "LIVE",
        "pt": 0
    },
    "greendragon": {
        "name": "D88 Green-Dragon",
        "type": "LIVE",
        "pt": 0
    },
    "hotgraph_amb": {
        "name": "Hotgraph",
        "type": "Trading",
        "pt": 0
    },
    "iampoker": {
        "name": "Poker",
        "type": "Poker",
        "pt": 0
    },
    "number": {
        "name": "Number",
        "type": "Keno",
        "pt": 0
    },
    "rng_war": {
        "name": "RNG War",
        "type": "Keno",
        "pt": 0
    },
    "keno": {
        "name": "Keno",
        "type": "Keno",
        "pt": 0
    },
    "atom": {
        "name": "Atom",
        "type": "Keno",
        "pt": 0
    },
    "allwayspin": {
        "name": "AllWaySpin",
        "type": "SLOT",
        "pt": 0
    },
    "ambslot": {
        "name": "AMB Slot",
        "type": "SLOT",
        "pt": 0
    },
    "ameba": {
        "name": "Ameba",
        "type": "SLOT",
        "pt": 0
    },
    "askmebet": {
        "name": "Askmebet",
        "type": "SLOT",
        "pt": 0
    },
    "booongo": {
        "name": "Booongo",
        "type": "SLOT",
        "pt": 0
    },
    "cq9": {
        "name": "CQ9",
        "type": "SLOT",
        "pt": 93
    },
    "cg": {
        "name": "Creative Gaming",
        "type": "SLOT",
        "pt": 93
    },
    "evoplay": {
        "name": "Evoplay",
        "type": "SLOT",
        "pt": 93
    },
    "funkygames": {
        "name": "Funky Games",
        "type": "SLOT",
        "pt": 0
    },
    "funta_gaming": {
        "name": "FunTa Gaming",
        "type": "SLOT",
        "pt": 0
    },
    "gamatron": {
        "name": "Gamatron",
        "type": "SLOT",
        "pt": 93
    },
    "habanero": {
        "name": "Habanero",
        "type": "SLOT",
        "pt": 93
    },
    "iconic_gaming": {
        "name": "Iconic Gaming",
        "type": "SLOT",
        "pt": 93
    },
    "jili": {
        "name": "Jilli",
        "type": "SLOT",
        "pt": 91
    },
    "joker": {
        "name": "Joker",
        "type": "SLOT",
        "pt": 92
    },
    "ka_gaming": {
        "name": "KAGaming",
        "type": "SLOT",
        "pt": 0
    },
    "kingmaker": {
        "name": "King Maker",
        "type": "SLOT",
        "pt": 92
    },
    "live22": {
        "name": "Live22",
        "type": "SLOT",
        "pt": 89
    },
    "manna_play": {
        "name": "Manaplay",
        "type": "SLOT",
        "pt": 90
    },
    "micro_game": {
        "name": "Micro Gaming",
        "type": "SLOT",
        "pt": 91
    },
    "netent": {
        "name": "Netent",
        "type": "SLOT",
        "pt": 92
    },
    "pgslot": {
        "name": "PG Slot ( AMB )",
        "type": "SLOT",
        "pt": 0
    },
    "pgsoft": {
        "name": "PG Soft",
        "type": "SLOT",
        "pt": 92
    },
    "relax_gaming": {
        "name": "Relax Gaming",
        "type": "SLOT",
        "pt": 0
    },
    "simpleplay": {
        "name": "Simple Play",
        "type": "SLOT",
        "pt": 93
    },
    "slotracha": {
        "name": "Slot Racha",
        "type": "SLOT",
        "pt": 0
    },
    "slotxo": {
        "name": "SlotXO",
        "type": "SLOT",
        "pt": 90
    },
    "spade": {
        "name": "Spade Gaming",
        "type": "SLOT",
        "pt": 92
    },
    "spinix": {
        "name": "Spinix",
        "type": "SLOT",
        "pt": 0
    },
    "upg_slot": {
        "name": "UPG Slot",
        "type": "SLOT",
        "pt": 0
    },
    "wazdan_direct": {
        "name": "Wazdan Direct",
        "type": "SLOT",
        "pt": 0
    },
    "yggdrasil": {
        "name": "Yggdrasill",
        "type": "SLOT",
        "pt": 92
    },
    "ambpoker": {
        "name": "AMB Poker",
        "type": "CARD",
        "pt": 0
    },
    "mpoker": {
        "name": "Mpoker",
        "type": "CARD",
        "pt": 0
    },
    "p8": {
        "name": "P8",
        "type": "CARD",
        "pt": 0
    },
    "ludo": {
        "name": "Ludo",
        "type": "POKER",
        "pt": 0
    },
    "sv388": {
        "name": "Cock Fight",
        "type": "LIVE",
        "pt": 0
    },
    "jdb": {
        "name": "JDB",
        "type": "SLOT",
        "pt": 0
    },
    "venus": {
        "name": "Venus Gaming",
        "type": "LIVE",
        "pt": 0
    },
    "yl": {
        "name": "YL",
        "type": "SLOT",
        "pt": 0
    },
    "e1sport": {
        "name": "E1SPORT",
        "type": "ESPORT",
        "pt": 0
    },
    "fc": {
        "name": "Fachai Gaming",
        "type": "SLOT",
        "pt": 0
    },
    "fastspin": {
        "name": "Fastspin",
        "type": "SLOT",
        "pt": 0
    },
    "horsebook": {
        "name": "Horse Racing",
        "type": "LIVE",
        "pt": 0
    },
    "aws": {
        "name": "AE Gaming",
        "type": "SLOT",
        "pt": 0
    },
    "saba": {
        "name": "SABA",
        "type": "ESPORT",
        "pt": 0
    },
    "luckypoker": {
        "name": "Lucky Poker",
        "type": "POKER",
        "pt": 0
    },
    "naga": {
        "name": "NAGA Games",
        "type": "SLOT",
        "pt": 0
    }
}


export const getProvider = (code: string = 'UNKNOWN', Providers: any) => {
    if (Providers.hasOwnProperty(code)) {
        let game = Providers[code]
        game.key = code
        return game
    }
    return code
}


export const getGamesType = {
    'SLOT': "Game Slot",
    'LIVE': "LIVE Casino",
    'CARD': "Game Card",
    'POKER': "Poker",
    'TRADING': "Trading",
    'KENO': "Keno",
    'ESPORT': "E-SPORT",
}

export const ProviderType = {
    'SLOT': "SLOT",
    'LIVE': "LIVE CASINO",
    'CARD': "CARD",
    'POKER': "POKER",
    'TRADING': "TRADING",
    'KENO': "KENO",
    'ESPORT': "E-SPORT",
}

export const gameType: Record<string, string> = {
    SLOT: 'SLOT',
    LIVE: 'LIVE',
    FISHING: 'FISHING',
    TABLE: 'TABLE',
    EGAME: 'EGAME',
    ESPORTS: 'ESPORTS',
    BINGO: 'BINGO',
    CARD: 'CARD',
    ARCADE: 'ARCADE',
    SICBO: 'SICBO',
}

export const gameLobby: Record<number, string> = [
    "HOT-100",
    "HOT-NEW",
    "HOT-SALESPIN",
    "HOT-FISHING",
    "HOT-BREAK",
    "HOT-50",
    "HOT-ARCADE",
    "HOT-OTHER",
]