import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Divider
} from 'antd'
import { toast } from 'react-toastify'
import ServiceAPI from '~services/services'
import to from 'await-to-js'
import { AddAnnouncersAgent } from '~types/announcers'

interface AddProps {
    close: () => void
    onloadData: () => void
    isOpen: boolean
}

const AddAnnouncer: FC<AddProps> = ({ close, isOpen, onloadData }) => {
    const [form] = Form.useForm();
    const title = "Add Announcer"
    const [btnLoading, setBtnLoading] = useState(false)

    const insertData = async (values: AddAnnouncersAgent) => {
        const [err, res] = await to(ServiceAPI.addAnnouncerAgent(values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res.message)
            setBtnLoading(false)
            onloadData()
            close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: AddAnnouncersAgent) => {
                setBtnLoading(true)
                insertData(values)
            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
        }
    }, [form, isOpen]);

    return (
        <>

            <Drawer
                title={title}
                onClose={close}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            Save
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="addAnnouncer"
                    layout="vertical">
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Message"
                                rules={[{ required: true, message: 'Please input your Message!' }]}
                                hasFeedback>
                                <Input placeholder="Message" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> Save</Button>
                            <Button onClick={close} size="large">Close</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default AddAnnouncer

