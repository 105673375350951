import React, { Fragment, useState, useEffect } from 'react'
// import { useNavigate } from "react-router-dom"
import { Image, Space, Switch, Tooltip, Select, Typography } from 'antd'
// import { DeleteOutlined } from '@ant-design/icons'
// import { UserOutlined } from '@ant-design/icons';
import Button from 'antd-button-color'
// import moment from 'moment'
import TitleBar from '~components/Elements/TitleBar'
// import FormattedMessage from '~components/Elements/FormattedMessage'
// import DatePickerRange from '~components/Elements/DatePickerRange'
// import DateFilter from '~components/Elements/DateFilter'
import Table2 from '~components/Elements/Table2'
// import BadgeComponent from '~components/Elements/BadgeComponent'
import Search from '~components/Elements/Search'
// import useDateRange from "~hooks/useDateRange"
// import useDatePagination from "~hooks/useDatePagination"
import NumberWithCommas from '~utils/NumberWithCommas'
import useFetchGames from '~hooks/useFetchGames';
import useFetchProviders from '~hooks/useFetchProviders'
import { ProviderData } from '~types/game'
import ImageError from '~utils/ImageError'
import Fuse from 'fuse.js'
import to from 'await-to-js'
import { toast } from 'react-toastify'
import ServiceAPI from '~services/services'
import AddGames from '~components/Games/Add'
import EditGames from '~components/Games/Edit'
import ViewGames from '~components/Games/View'
import { gameType } from '~utils/games'
import { ApplicationState } from '~store/index'
import { useSelector } from 'react-redux'

const Games = () => {
    const routes = [
        {
            path: '',
            breadcrumbName: 'Games Management',
        }
    ];

    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissions = authState.data.permissions
    const [dataSource, setDataSource] = useState<any>([])
    const [filterProvider, setFilterProvider] = useState<string>("")
    const [filterTypeGame, setFilterTypeGame] = useState<string>("")
    const [filterType, setFilterType] = useState<string>("gameName")
    // const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [switchLoadingActive, setSwitchLoadingActive] = useState<boolean>(false)
    const [switchLoadingDisplay, setSwitchLoadingDisplay] = useState<boolean>(false)

    const [filterText, setFilterText] = useState('')
    const [searchAlldata, setSearchAllData] = useState<string>("")

    const [isAddOpen, setIsAddOpen] = useState(false)
    const toggleAdd = () => setIsAddOpen(!isAddOpen)

    const [isEditOpen, setIsEditOpen] = useState(false)
    const toggleEdit = () => setIsEditOpen(!isEditOpen)

    const [isViewOpen, setIsViewOpen] = useState(false)
    const toggleView = () => setIsViewOpen(!isViewOpen)

    const [gamesData, setGamesData] = useState<any>()
    const handleEdit = (object: any) => setGamesData(object)


    const { data: dataProvider, isLoading: isLoadingProvider } = useFetchProviders()
    const { data: dataGame, isLoading, mutate } = useFetchGames()

    // const handleDelete = (id: string, gameName: string) => {
    //     Modal.confirm({
    //         title: `You want to delete the game ${gameName}?`,
    //         icon: <DeleteOutlined />,
    //         content: `Make sure you want to delete the game ${gameName}?`,
    //         okText: 'Yes',
    //         cancelText: 'No',
    //         onOk() {
    //             (async () => {
    //                 const [err, res] = await to(ServiceAPI.deleteGame(id))
    //                 if (err) {
    //                     toast.error(err?.message as string)
    //                     setDeleteLoading(false)
    //                 } else {
    //                     mutate()
    //                     toast.success(res.message)
    //                     setDeleteLoading(false)
    //                 }
    //             })();
    //         },
    //         onCancel() {
    //             setDeleteLoading(false)
    //         },
    //     });
    // }

    const handleStatus = async (e: any, type: string, dataGame: any, status: boolean) => {
        let dataSend: any = {
            id: dataGame.id || "",
            provider: dataGame.providerCode,
            gameName: dataGame.gameName,
            gameType: dataGame.type,
            gameCode: dataGame.gameCode,
            image: ""
        }
        if (type === "display") {
            dataSend.display = e
            dataSend.active = status
            const [err, res] = await to(ServiceAPI.editGames(dataSend))
            if (err) {
                toast.error(err?.message as string)
                setSwitchLoadingDisplay(false)
            } else {
                mutate()
                toast.success(res.message)
                setSwitchLoadingDisplay(false)
            }
        } else if (type === "active") {
            dataSend.active = e
            dataSend.display = status
            const [err, res] = await to(ServiceAPI.editGames(dataSend))
            if (err) {
                toast.error(err?.message as string)
                setSwitchLoadingActive(false)
            } else {
                mutate()
                toast.success(res.message)
                setSwitchLoadingActive(false)
            }
        }
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'Image',
            align: 'center',
            render: (text: any, record: any) => (
                <Image
                    className='ant-img-circle'
                    width={32}
                    height={32}
                    alt={record.gameName}
                    src={record.imageURL ? record.imageURL : 'error'}
                    fallback={ImageError}
                />
            )
        },
        {
            title: 'Game Name',
            dataIndex: 'gameName',
            render: (text: any, record: any) => (
                <div
                    className="text-link"
                    onClick={() => {
                        handleEdit(record)
                        toggleView()
                    }}>
                    <Typography.Text strong>{record.gameName}</Typography.Text>
                </div>
            )
        },
        {
            title: 'Game Code',
            dataIndex: 'gameCode',
            render: (text: any, record: any) => (
                <div>{record.gameCode}</div>
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (text: any, record: any) => (
                <div>{(record.type).toUpperCase()}</div>
            )
        },
        {
            title: 'Provider',
            dataIndex: 'provider',
            render: (text: any, record: any) => (
                <div>{record.providerName}</div>
            )
        },
        {
            title: 'Display',
            dataIndex: 'display',
            align: 'center',
            render: (text: any, record: any) => (
                <Space direction="vertical">
                    <Tooltip title="Open/Close">
                        <Switch
                            checkedChildren="Open"
                            unCheckedChildren="Close"
                            checked={record.display}
                            disabled={permissions && (permissions["GAMES-MANAGEMENT"] === "VIEW" || permissions["GAMES-MANAGEMENT"] === "OFF")}
                            loading={switchLoadingDisplay}
                            onChange={(e) => {
                                setSwitchLoadingDisplay(true)
                                handleStatus(e, "display", record, record.active)
                            }}
                        />
                    </Tooltip>
                </Space>
            )
        },
        {
            title: 'Maintenance',
            dataIndex: 'active',
            align: 'center',
            render: (text: any, record: any) => (
                <Space direction="vertical">
                    <Tooltip title="Yes/No">
                        <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={record.active}
                            disabled={permissions && (permissions["GAMES-MANAGEMENT"] === "VIEW" || permissions["GAMES-MANAGEMENT"] === "OFF")}
                            loading={switchLoadingActive}
                            onChange={(e) => {
                                setSwitchLoadingActive(true)
                                handleStatus(e, "active", record, record.display)
                            }}
                        />
                    </Tooltip>
                </Space>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            ellipsis: true,
            width: 140,
            align: 'center',
            hidden: permissions && (permissions["GAMES-MANAGEMENT"] === "VIEW" || permissions["GAMES-MANAGEMENT"] === "OFF"),
            render: (text: any, record: any) => (
                <Space size={0}>
                    <Button
                        type="warning"
                        size="small"
                        className="me-2"
                        icon={<i className="fa-solid fa-edit me-2"></i>}
                        onClick={() => {
                            handleEdit(record)
                            toggleEdit()
                        }}>
                        Edit
                    </Button>
                    {/* <Button
                        type="danger"
                        size="small"
                        icon={<i className="fa-solid fa-trash me-2"></i>}
                        loading={deleteLoading}
                        onClick={() => {
                            setDeleteLoading(true)
                            handleDelete(record.id, record.gameName)
                        }}>
                        Delete
                    </Button> */}
                </Space >
            )
        }
    ].filter(item => !item.hidden);




    useEffect(() => {
        setDataSource([])
        let arrGame: any = []
        if (dataGame !== undefined && dataGame.data.length > 0) {
            (dataGame.data).forEach((element: ProviderData) => {
                if (element.data.length > 0) {
                    element.data.forEach((game: any) => {
                        game.providerName = element.name
                        game.providerCode = element.code
                        arrGame.push(game)
                    });
                }
            });
        }
        let fuseSearch = new Fuse<any>(arrGame, {
            keys: ['providerCode', 'type', 'gameName', 'gameCode'],
        })
        if (filterProvider !== undefined && filterProvider !== '') {
            fuseSearch = new Fuse<any>(arrGame, {
                useExtendedSearch: true,
                keys: ["providerCode" as string],
            })
            arrGame = fuseSearch.search(`${filterProvider}$`).map((x) => x.item)
        }

        if (filterTypeGame !== undefined && filterTypeGame !== '') {
            fuseSearch = new Fuse<any>(arrGame, {
                useExtendedSearch: true,
                keys: ["type" as string],
            })
            arrGame = fuseSearch.search(`${filterTypeGame}$`).map((x) => x.item)
        }
        if (searchAlldata !== undefined && searchAlldata !== '') {
            const fuseSearch = new Fuse<any>(arrGame, {
                useExtendedSearch: true,
                keys: [filterType as string],
            })
            arrGame = fuseSearch.search(`^${searchAlldata}`).map((x) => x.item)
        }

        setDataSource(arrGame)
        return () => {
            setDataSource([])
        }
    }, [dataGame, filterProvider, filterTypeGame, searchAlldata, filterType]) // eslint-disable-line

    const handleSearch = () => {
        setSearchAllData(filterText)
    }

    const optionGameType = Object.keys(gameType).map((key: any) => {
        return <Select.Option key={key} value={key}>{gameType[key]}</Select.Option>
    })

    return (
        <>
            <TitleBar title="Games" subTitle={`${NumberWithCommas(dataSource?.length || 0)} items`} routes={routes} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <Space>
                    <Select
                        style={{ width: 200 }}
                        placeholder="Select Provider"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e: any) => setFilterProvider(e)}
                        allowClear={true}>
                        {(!isLoadingProvider && dataProvider !== undefined && dataProvider.data.length > 0) && Object.values(dataProvider.data).map((item: any) => (
                            <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
                        ))}
                    </Select>
                    <Select
                        style={{ width: 200 }}
                        placeholder="Select Type"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e: any) => setFilterTypeGame(e)}
                        allowClear={true}>
                        {optionGameType}
                    </Select>
                </Space>
            </div>
            <div className="d-flex justify-content-start mb-4">
                <Space>
                    <Select
                        style={{ width: 120 }}
                        placeholder="Type Search"
                        showSearch
                        optionFilterProp="children"
                        onChange={(e: string) => setFilterType(e)}
                        defaultValue="gameName"
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        <Select.Option value="gameName">Game Name</Select.Option>
                        <Select.Option value="gameCode">Game Code</Select.Option>
                    </Select>
                    <Search onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                    <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">Search</button>
                </Space>
            </div>
            {permissions && permissions["GAMES-MANAGEMENT"] === "EDIT" && (
                <div className="row mb-md-3 align-items-md-center">
                    <div className="mb-3 mb-md-0">
                        <Button
                            type="primary"
                            className="d-block ms-auto"
                            icon={<i className="fa-solid fa-plus me-3"></i>}
                            onClick={toggleAdd}>
                            Add Game
                        </Button>
                    </div>
                </div>
            )}
            <div className="box-white ant-box-table">
                <Table2
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    bordered={true}
                />
            </div>
            {isAddOpen && (
                <AddGames
                    isOpen={isAddOpen}
                    close={toggleAdd}
                    onloadData={mutate}
                    provider={(dataProvider !== undefined && dataProvider.data.length > 0) && dataProvider.data}
                />
            )}
            {isEditOpen && (
                <EditGames
                    isOpen={isEditOpen}
                    close={toggleEdit}
                    onloadData={mutate}
                    data={gamesData}
                    provider={(dataProvider !== undefined && dataProvider.data.length > 0) && dataProvider.data}
                />
            )}

            {isViewOpen && (
                <ViewGames
                    isOpen={isViewOpen}
                    close={toggleView}
                    data={gamesData}
                    swapDataEdit={() => {
                        toggleView()
                        toggleEdit()
                    }}
                />
            )}
        </>
    );
};

export default Games