import useSWR from 'swr'
import ServiceAPI from '~services/services'

export const useFetchAnnouncerMember = () => {
    const { data, mutate, error } = useSWR([`/announcer-member`, 'member'], () => ServiceAPI.getAnnouncerMember());

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchAnnouncerMember
