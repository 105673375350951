import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '~store/index'
import ProfileButton from './ProfileButton'
import NamePrefix from './NamePrefix'
// import TranslationButton from './TranslationButton'
interface HeaderProps {
    toggleNavbar: boolean
    setToggleNavbar: (toggle: boolean) => void
}

const HeaderMobile: FC<HeaderProps> = ({ toggleNavbar, setToggleNavbar }) => {
    const authState = useSelector((state: ApplicationState) => state.auth)
    const username = authState.data.username
    const role = authState.data.user?.role
    return (
        <nav className="navbar msn-navbar d-lg-none" style={{ flexWrap: 'nowrap', boxShadow: 'none' }}>
            <div className="container-fluid position-relative px-0">
                <div className="msn-nav-toggle" onClick={() => setToggleNavbar(!toggleNavbar)}>
                    <i className={"fal fa-bars " + (toggleNavbar ? '' : 'open')}></i>
                    <i className={"fal fa-times " + (toggleNavbar ? 'open' : '')}></i>
                </div>
                <NamePrefix className='name-prefix-mobile' />
                <ProfileButton username={username} role={role} />
            </div>
        </nav>
    )
}

export default HeaderMobile