import React, { FC } from 'react'
import { Breadcrumb as BreadcrumbAntd } from 'antd'
import { HomeOutlined } from '@ant-design/icons'

interface BreadcrumbProps {
    routes?: routesProps[]
}
interface routesProps {
    path?: string
    breadcrumbName?: string
}
const Breadcrumb: FC<BreadcrumbProps> = ({ routes }) => {
    const breadcrumbItems = routes && routes.map((route, index) => {
        if (route.path) {
            return (
                <BreadcrumbAntd.Item key={index} href={route.path}>
                    {route.breadcrumbName}
                </BreadcrumbAntd.Item>
            )
        } else {
            return (
                <BreadcrumbAntd.Item key={index} >
                    {route.breadcrumbName}
                </BreadcrumbAntd.Item>
            )
        }
    })

    return (
        <BreadcrumbAntd className="mb-lg-2">
            <BreadcrumbAntd.Item href="/">
                <HomeOutlined style={{ verticalAlign: 1 }}/>
            </BreadcrumbAntd.Item>
            {breadcrumbItems}
        </BreadcrumbAntd>
    )
}

export default Breadcrumb