import actionCreatorFactory from 'typescript-fsa'
import { IProvider } from '~types/provider'
import { createRequestThunk } from '~utils/store'
import { CommonResponse } from '~types/api'
import ServiceAPI from '~services/services'

const actionCreator = actionCreatorFactory('@@msnslot-master/auth')

export const requestProviderInitActions = actionCreator.async<boolean, IProvider, CommonResponse>('requestProviderInit')


export const requestProviderInit = createRequestThunk<boolean, IProvider, CommonResponse>(
	requestProviderInitActions,
	() =>
		ServiceAPI.getProviderPercentage().catch((err) => {
			return Promise.reject(err)
		})
)