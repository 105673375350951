import React, { useEffect, useState } from 'react'
import Button from 'antd-button-color'
import TitleBar from '~components/Elements/TitleBar'
// import FormattedMessage from '~components/Elements/FormattedMessage'
import CardPopup from '~components/Popup/Card'
import NumberWithCommas from '~utils/NumberWithCommas'
import useFetchPopup from '~hooks/useFetchPopup'
import AddPopup from '~components/Popup/Add'
import { ApplicationState } from '~store/index'
import { useSelector } from 'react-redux'

const Popup = () => {

    const routes = [
        {
            path: '',
            breadcrumbName: 'Popup',
        },
    ];

    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissions = authState.data.permissions
    const [dataSource, setDataSource] = useState<any>([])
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const toggleAdd = () => setIsAddOpen(!isAddOpen)

    const { data, mutate, isLoading } = useFetchPopup()

    useEffect(() => {
        setDataSource([])

        if (data !== undefined) {
            Object.entries(data?.data).map((item: any) => (
                setDataSource((prev: any) => [...prev, {
                    key: item[0],
                    thumbnail: item[1],
                }])
            ))
        }

        return () => {
            setDataSource([])
        }
    }, [data, isLoading, setDataSource])

    return (
        <>
            <TitleBar title="Popup" subTitle={`${NumberWithCommas(dataSource.length || 0)} items`} routes={routes} />
            {permissions && permissions["POPUP-MANAGEMENT"] === "EDIT" && (
                <div className="row mb-md-3 align-items-md-center">
                    <div className="mb-3 mb-md-0">
                        <Button
                            type="primary"
                            className="d-block ms-auto"
                            icon={<i className="fa-solid fa-plus me-3"></i>}
                            onClick={toggleAdd}>
                            Add Popup
                        </Button>
                    </div>
                </div>
            )}
            <div className="box-white ant-box-table">
                <CardPopup
                    dataSource={dataSource || []}
                    loading={isLoading}
                    onLoadData={mutate}
                />
            </div>
            {isAddOpen && (
                <AddPopup
                    isOpen={isAddOpen}
                    close={toggleAdd}
                    onloadData={mutate}
                />
            )}
            {/* {isEditOpen && (
                <EditTopgraphic
                    isOpen={isEditOpen}
                    close={toggleEdit}
                    onloadData={mutate}
                    data={topgraphicData}
                />
            )}
            {isViewOpen && (
                <ViewTopgraphic
                    isOpen={isViewOpen}
                    close={toggleView}
                    data={topgraphicData}
                    swapDataEdit={() => {
                        toggleView()
                        toggleEdit()
                    }}
                />
            )} */}
        </>
    )
}

export default Popup