import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Divider,
    Upload
} from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import ServiceAPI from '~services/services'
import to from 'await-to-js'

interface AddProps {
    close: () => void
    onloadData: () => void
    isOpen: boolean
}

const AddTopgraphic: FC<AddProps> = ({ close, isOpen, onloadData }) => {
    const [form] = Form.useForm();
    const title = "Add Topgraphic"
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [imageThumbnail, setImageThumbnail] = useState<string>("")
    const [imageBackground, setImageBackground] = useState<string>("")

    const insertData = async (values: any) => {
        const [err, res] = await to(ServiceAPI.addTopgraphic(values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res.message)
            setBtnLoading(false)
            onloadData()
            close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                if (!imageThumbnail || !imageBackground) {
                    toast.error("Please upload image")
                    setBtnLoading(false)
                }
                values.thumbnail = imageThumbnail
                values.background = imageBackground
                insertData(values)
            })
            .catch((errorInfo) => { });
    };


    const buttonUploadThumbnail = (
        <div>
            {imageThumbnail ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload Thumbnail</div>
        </div>
    );

    const buttonUploadBackground = (
        <div>
            {imageBackground ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload Background</div>
        </div>
    );

    const uploadImage = async (response: any, type: string) => {
        setBtnLoading(true)
        let formData: FormData = new FormData();
        formData.append('file', response.file);
        if (type === "thumbnail") {
            const [err, res] = await to(ServiceAPI.uploadImage(formData))
            if (err) {
                toast.error(err?.message as string)
                setBtnLoading(false)
            } else {
                toast.success(res?.message as string)
                setImageThumbnail(res?.link)
                setBtnLoading(false)
            }
        } else if (type === "background") {
            const [err, res] = await to(ServiceAPI.uploadImage(formData))
            if (err) {
                toast.error(err?.message as string)
                setBtnLoading(false)
            } else {
                toast.success(res?.message as string)
                setImageBackground(res?.link)
                form.setFieldsValue({ "background": res.link })
                setBtnLoading(false)
            }
        } else {
            return false
        }
    }

    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
        }
    }, [form, isOpen]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            Save
                        </Button>
                    </Space>
                }>
                <Form
                    form={form}
                    name="addTopgraphic"
                    layout="vertical">
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="topic"
                                label="Topic"
                                rules={[{ required: true, message: 'Please input your Topic!' }]}
                                hasFeedback>
                                <Input placeholder="Topic" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Description"
                                rules={[{ required: true, message: 'Please input your Description!' }]}
                                hasFeedback>
                                <Input.TextArea placeholder="Description..." />
                            </Form.Item>
                        </Col>
                        <Row>
                            <Col span={12}>
                                <label style={{ marginBottom: '16px' }}>Thumbnail</label>
                                <Upload
                                    name="thumbnail-image"
                                    listType="picture-card"
                                    showUploadList={false}
                                    customRequest={(e) => uploadImage(e, 'thumbnail')}
                                >
                                    {imageThumbnail ? <img src={imageThumbnail} alt="thumbnail" style={{ width: '100%' }} /> : buttonUploadThumbnail}
                                </Upload>
                            </Col>
                            <Col span={12}>
                                <label style={{ marginBottom: '16px' }}>Background</label>
                                <Upload
                                    name="background-image"
                                    listType="picture-card"
                                    showUploadList={false}
                                    customRequest={(e) => uploadImage(e, 'background')}
                                >
                                    {imageBackground ? <img src={imageBackground} alt="background" style={{ width: '100%' }} /> : buttonUploadBackground}
                                </Upload>
                            </Col>
                        </Row>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> Save</Button>
                            <Button onClick={close} size="large">Close</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default AddTopgraphic

