import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { useNavigate, useParams, Navigate } from "react-router-dom"
import { toast } from 'react-toastify'
import {
    Form,
    Input,
    Row,
    Col,
    PageHeader,
    Divider,
    Space,
    Button,
    Typography,
    Radio
} from 'antd'
import to from 'await-to-js'
import {
    SubUserEdit
} from '~types/users'
import { ThunkDispatch } from '~types/store'
import TitleBar from '~components/Elements/TitleBar'
import EmptyComponents from '~components/Elements/Empty'
import Loading from '~components/Elements/Loading'
import { ApplicationState } from '~store/index'
import { requestAuthInit } from '~store/auth/auth.actions'
import { permissions, permissionRadioOption } from '~utils/permissions'
import ServiceAPI from '~services/services'

const EditAccount = () => {
    const { username } = useParams();
    const [form] = Form.useForm();
    let navigate = useNavigate();
    const routes = [
        {
            path: '/sub-accounts',
            breadcrumbName: 'Sub Accounts',
        },
        {
            path: '',
            breadcrumbName: 'Edit Sub Account',
        },
    ];

    const authState = useSelector((state: ApplicationState) => state.auth)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [dataSource, setDataSource] = useState<any>([])
    const [dataLoading, setDataLoading] = useState<boolean>(false)
    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    const handleFetchData = async (username: string) => {
        if (!username) {
            setDataLoading(false)
            return <Navigate to="/sub-accounts" replace />
        }
        setDataSource([])
        setDataLoading(true)
        const [err, res] = await to(ServiceAPI.getSubUser(username))
        if (err) {
            toast.error(err?.message as string)
            setDataLoading(false)
        } else {
            setDataSource(res.data)
            setDataLoading(false)
        }
    }

    const updateData = async (username: string, values: SubUserEdit | any) => {
        if (!username) {
            setBtnLoading(false)
            return false
        }
        delete values.username
        const [err, res] = await to(ServiceAPI.updateSubUser(username, values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
            navigate(`/sub-accounts`)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: SubUserEdit | any) => {
                setBtnLoading(true)
                updateData(values.username, values)
            })
            .catch((errorInfo) => {

            });
    };

    const listPermissions = permissions && Object.keys(permissions).map((item: any) => {
        const lable = permissions[item].name
        return (
            <Col xs={24} sm={12} key={`col-permission-${item}`}>
                <Form.Item
                    label={lable}
                    name={['permissions', item]}
                    key={item}
                    rules={[{ required: true, message: 'Please pick an permission!' }]}
                    initialValue={"OFF"}>
                    <Radio.Group
                        options={permissionRadioOption}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </Form.Item>
            </Col>
        )
    })

    useEffect(() => {
        if (form && username) {
            form.resetFields();
            handleFetchData(username)
            permissions && Object.keys(permissions).forEach((item: any) => {
                form.setFieldsValue({
                    'permissions': {
                        [`${item}`]: permissionRadioOption[0].value
                    }
                })
            })
        }
    }, [form, username, authState])

    useEffect(() => {
        if (form && dataSource !== undefined && Object.keys(dataSource).length > 0) {
            form.resetFields();
            form.setFieldsValue(dataSource)
        }
    }, [form, dataSource])


    return (
        <Fragment>
            <TitleBar title="Sub Accounts" routes={routes} />
            <PageHeader
                className="site-page-header"
                onBack={() => navigate(`/sub-accounts`)}
                title="Edit Sub Account"
            />

            {dataLoading ? <Loading /> : (
                dataSource && Object.keys(dataSource).length > 0
                    ? (
                        <div className="box-white ant-box-card">
                            <Form
                                form={form}
                                name="editSubAccounts"
                                layout="vertical">
                                <Typography.Title level={4} style={{ marginBottom: 16 }}>Sub Account Information</Typography.Title>
                                <Row gutter={16}>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="username"
                                            label="Username"
                                            rules={[{ required: false, message: 'Please input your username!' }]}>
                                            <Input placeholder="Username" inputMode="text" autoComplete="new-username" disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[{ required: true, message: 'Please input your name!' }]}
                                            hasFeedback>
                                            <Input placeholder="Name" inputMode="text" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="phone"
                                            label="Phone Number"
                                            rules={[{ required: false, message: 'Please input your phone number!' }]}>
                                            <Input placeholder="Phone Number" inputMode="tel" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Typography.Title level={4} style={{ marginBottom: 16 }}>Permissions</Typography.Title>
                                <Row gutter={16}>
                                    {listPermissions}
                                </Row>
                                <Divider />
                                <div className="drawer-actions">
                                    <Space>
                                        <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large" className="text-white me-2">
                                            <i className="fa-solid fa-save me-2"></i>
                                            Save
                                        </Button>
                                    </Space>
                                </div>
                            </Form>
                        </div>
                    ) : (<EmptyComponents />)
            )}
        </Fragment>
    )
}

export default EditAccount