import useSWR from 'swr'
import ServiceAPI from '~services/services'

export const useFetchAnnouncerAgent = () => {
    const { data, mutate, error } = useSWR([`/announcer-agent`, 'agent'], () => ServiceAPI.getAnnouncerAgent());

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchAnnouncerAgent
