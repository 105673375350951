import React, { useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import AuthService from '~services/auth'
import styled from 'styled-components'
import { Colors } from '~utils/color'

const Logout = () => {
    const [showChildren, setShowChildren] = useState(false)
    useEffect(() => {
        AuthService.revokeToken()
        setShowChildren(true)
    }, [])
    const [searchParams] = useSearchParams();


    if (!showChildren) {
        return (
            <CenterContainer className="main-container">
                <i className="fad fa-spinner fa-spin fa-2x" />
            </CenterContainer>
        )
    }

    if (searchParams.get("redirect")) {
        window.location.href = 'https://google.com'
        return null;
    }

    return <Navigate to="/login" replace />
}

export default Logout


const CenterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;

	h4 {
		color: ${Colors.negative};
		font-family: 'Sarabun, sans-serif';
	}
`