import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        if (pathname !== '/' && pathname !== '/secure-code') {
            document.getElementsByClassName("main-container")[0].scrollTo({ top: 0, behavior: 'smooth' })
            document.getElementsByClassName("sidebar-container")[0].classList.remove("open-menu")
            document.querySelector('.msn-nav-toggle .fal.fa-bars')?.classList.add('open');
            document.querySelector('.msn-nav-toggle .fal.fa-times')?.classList.remove('open');
            document.body.classList.remove('disabled-scrolling');
        }
    }, [pathname]);

    return null;
}

