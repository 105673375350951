import React, { FC, useEffect, useState } from 'react'
import {
    Drawer,
    Space,
    Divider,
    Descriptions,
    Tag,
    Skeleton
} from 'antd'
import { useNavigate } from "react-router-dom"
import moment from 'moment'
import { toast } from 'react-toastify'
import to from 'await-to-js'
import { useSelector } from 'react-redux'
import ServiceAPI from '~services/services'
import { permissions } from '~utils/permissions'
import { ApplicationState } from '~store/index'
import Button from 'antd-button-color'

interface ViewProps {
    username: string
    close: () => void
    isOpen: boolean
}

const ViewSubAccount: FC<ViewProps> = ({ close, isOpen, username }) => {
    let navigate = useNavigate();
    const [dataSource, setDataSource] = useState<any>([])
    const [dataLoading, setDataLoading] = useState<boolean>(false)

    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissionsData = authState.data.permissions

    const handleFetchData = async (username: string) => {
        if (username) {
            setDataSource([])
            setDataLoading(true)
            const [err, res] = await to(ServiceAPI.getSubUser(username))
            if (err) {
                toast.error(err?.message as string)
                setDataLoading(false)
            } else {
                setDataSource(res.data)
                setDataLoading(false)
            }
        }

    }
    useEffect(() => {
        setDataSource([])
        if (isOpen && username) {
            setDataSource([])
            handleFetchData(username)
        }
    }, [isOpen, username])

    return (
        <Drawer
            title={`View Sub Account`}
            onClose={close}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .5}
            extra={
                <Space>
                    {permissionsData && permissionsData["SUB-USER-MANAGEMENT"] === "EDIT" && (
                        <Button
                            type="warning"
                            className="btn btn-warning btn-sm text-white me-2"
                            onClick={() => { navigate(`edit/${username}`) }}>
                            <i className="fa-solid fa-edit me-1"></i>
                            Edit</Button>
                    )}
                </Space>
            }>
            {dataLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Descriptions title={"Sub Account Information"} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                        <Descriptions.Item label="Username">{dataSource.username}</Descriptions.Item>
                        <Descriptions.Item label="Name">{dataSource.name}</Descriptions.Item>
                        <Descriptions.Item label="Phone Number">{dataSource.phone || "-"}</Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions title={"Permissions"} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }} bordered>
                        {dataSource && dataSource.permissions !== undefined && Object.keys(permissions).map((key: any) => {
                            const permission = dataSource.permissions[key] ? dataSource.permissions[key] : "OFF"
                            const namePermission = permissions[key].name
                            return <Descriptions.Item label={namePermission} key={key}>{permission}</Descriptions.Item>
                        })}
                    </Descriptions>
                    <Divider />
                    <Descriptions title="System Information" column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                        <Descriptions.Item label="Lock">
                            {dataSource.lock
                                ? <Tag color="red">Lock</Tag>
                                : <Tag color="green">Unlock</Tag>}
                        </Descriptions.Item>
                        <Descriptions.Item label="CreatedAt">{moment(dataSource.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Descriptions.Item>
                        <Descriptions.Item label="UpdatedAt">{moment(dataSource.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</Descriptions.Item>
                        <Descriptions.Item label="Last login">
                            {dataSource.loginAt !== "1970-01-01 07:00:00"
                                ? (<>
                                    {moment(dataSource.loginAt).format('DD/MM/YYYY HH:mm:ss')}
                                    <div className="msn-badge badge-light ms-2">{moment(dataSource.loginAt).fromNow()}</div>
                                </>) : "-"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Login IP">{dataSource.loginIP ? dataSource.loginIP : "-"}</Descriptions.Item>
                    </Descriptions>
                </>
            )}
        </Drawer>
    )
}

export default ViewSubAccount