import React, { Fragment, useState, useEffect } from 'react'
// import { useNavigate } from "react-router-dom"
import { Image, Select, Space, Switch, Tooltip, Typography } from 'antd'
import Button from 'antd-button-color'
// import moment from 'moment'
import TitleBar from '~components/Elements/TitleBar'
// import FormattedMessage from '~components/Elements/FormattedMessage'
// import DatePickerRange from '~components/Elements/DatePickerRange'
// import DateFilter from '~components/Elements/DateFilter'
// import BadgeComponent from '~components/Elements/BadgeComponent'
// import Search from '~components/Elements/Search'
// import useDateRange from "~hooks/useDateRange"
// import useDatePagination from "~hooks/useDatePagination"
import NumberWithCommas from '~utils/NumberWithCommas'
import useFetchProviders from '~hooks/useFetchProviders'
import ImageError from '~utils/ImageError'
import Table2 from '~components/Elements/Table2'
import Search from '~components/Elements/Search'
import Fuse from 'fuse.js'
import AddProviders from '~components/Providers/Add'
import EditProviders from '~components/Providers/Edit'
import ViewProviders from '~components/Providers/View'
import to from 'await-to-js'
import { toast } from 'react-toastify'
import ServiceAPI from '~services/services'
import moment from 'moment'
import { ApplicationState } from '~store/index'
import { useDispatch, useSelector } from 'react-redux'
import { requestProviderInit } from '~store/provider/provider.actions'
import { ThunkDispatch } from '~types/store'
import { AnyAction } from 'redux'

const Providers = () => {
    const routes = [
        {
            path: '',
            breadcrumbName: 'Providers Management',
        },
        {
            path: '',
            breadcrumbName: 'Providers',
        },
    ];

    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    // let navigate = useNavigate();
    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissions = authState.data.permissions
    const [dataSource, setDataSource] = useState<any>([])
    const [filterType, setFilterType] = useState<string>("name")
    const [switchLoadingStatus, setSwitchLoadingStatus] = useState<boolean>(false)
    // const { pagination, setPagination } = useDatePagination()
    // const { dateRange, setDateRange } = useDateRange()

    const [filterText, setFilterText] = useState('')
    const [searchAlldata, setSearchAllData] = useState<string>("")

    const [isAddOpen, setIsAddOpen] = useState(false)
    const toggleAdd = () => setIsAddOpen(!isAddOpen)

    const [isEditOpen, setIsEditOpen] = useState(false)
    const toggleEdit = () => setIsEditOpen(!isEditOpen)

    const [isViewOpen, setIsViewOpen] = useState(false)
    const toggleView = () => setIsViewOpen(!isViewOpen)

    const [providerData, setProviderData] = useState<any>()
    const handleEdit = (object: any) => setProviderData(object)


    const { data, isLoading, mutate } = useFetchProviders()

    const handleStatus = async (e: any, id: string) => {
        const data = {
            status: e
        }
        const [err, res] = await to(ServiceAPI.updateProvidersStatus(data, id))
        if (err) {
            toast.error(err?.message as string)
            setSwitchLoadingStatus(false)
        } else {
            mutate()
            toast.success(res.message)
            setSwitchLoadingStatus(false)
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text: any, record: any) => (
                <div
                    className="text-link"
                    onClick={() => {
                        handleEdit(record)
                        toggleView()
                    }}>
                    <Typography.Text strong>{record.name}</Typography.Text>
                </div>
            )
        },
        {
            title: 'Code',
            dataIndex: 'code',
            render: (text: any, record: any) => (
                <div>{record.code}</div>
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            align: 'center',
            render: (text: any, record: any) => (
                <div>{record.type || "-"}</div>
            )
        },
        {
            title: 'Image',
            dataIndex: 'imageURL',
            align: 'center',
            render: (text: any, record: any) => (
                <Image
                    className='ant-img-circle'
                    width={32}
                    height={32}
                    alt={record.name}
                    src={record.imageURL ? record.imageURL : 'error'}
                    fallback={ImageError}
                />
            )
        },
        {
            title: 'Background Image',
            dataIndex: 'backgroundImageURL',
            align: 'center',
            render: (text: any, record: any) => (
                <Image
                    className='ant-img-circle'
                    width={32}
                    height={32}
                    alt={record.name}
                    src={record.backgroundImageURL ? record.backgroundImageURL : 'error'}
                    fallback={ImageError}
                />
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            render: (text: any, record: any) => (
                <Space direction="vertical">
                    <Tooltip title="Open/Close">
                        <Switch
                            checkedChildren="Open"
                            unCheckedChildren="Close"
                            checked={record.status}
                            // defaultChecked={record.display}
                            disabled={permissions && (permissions["PROVIDERS-MANAGEMENT"] === "VIEW" || permissions["PROVIDERS-MANAGEMENT"] === "OFF")}
                            loading={switchLoadingStatus}
                            onChange={(e) => {
                                setSwitchLoadingStatus(true)
                                handleStatus(e, record.id)
                            }}
                        />
                    </Tooltip>
                </Space>
            )
        },
        {
            title: 'UpdatedAt',
            dataIndex: 'updatedAt',
            ellipsis: true,
            width: '140',
            align: 'center',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.updatedAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.updatedAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            ellipsis: true,
            width: 140,
            align: 'center',
            hidden: permissions && (permissions["PROVIDERS-MANAGEMENT"] === "VIEW" || permissions["PROVIDERS-MANAGEMENT"] === "OFF"),
            render: (text: any, record: any) => (
                <Space size={0}>
                    <Button
                        type="warning"
                        size="small"
                        className="me-2"
                        icon={<i className="fa-solid fa-edit me-2"></i>}
                        onClick={() => {
                            handleEdit(record)
                            toggleEdit()
                        }}>
                        Edit
                    </Button>
                </Space >
            )
        }
    ].filter(item => !item.hidden);


    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.data.length > 0) {
            let dataProvider = data.data
            if (searchAlldata !== undefined && searchAlldata !== '') {

                let fuseSearch = new Fuse<any>(data.data, {
                    keys: ['name', 'code'],
                })
                fuseSearch = new Fuse<any>(data.data, {
                    useExtendedSearch: true,
                    keys: [filterType as string],
                })
                dataProvider = fuseSearch.search(`^${searchAlldata}`).map((x) => x.item)
            }
            setDataSource(dataProvider)
        }
        return () => {
            setDataSource([])
        }
    }, [data, searchAlldata, filterType])

    // useMemo(() => {
    //     mutate()
    // }, [setDateRange, setPagination])// eslint-disable-line


    const handleSearch = () => {
        setSearchAllData(filterText)
    }


    const onloadData = () => {
        dispatch(requestProviderInit(true))
        mutate()
    }

    // useMemo(() => {
    //     if (searchParams.get("search")) {
    //         setFilterText(searchParams.get("search") || "")
    //         setSearchMember(searchParams.get("search") || "")
    //         mutate()
    //     }
    // }, [searchParams, setSearch])// eslint-disable-line

    return (
        <>
            <TitleBar title="Providers" subTitle={`${NumberWithCommas(dataSource?.length || 0)} items`} routes={routes} />
            {/* <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilter onChangeDate={setDateRange} onDateActive={dateRange} />
            </div> */}
            <div className="d-flex justify-content-start mb-4">
                <Space>
                    <Select
                        style={{ width: 120 }}
                        placeholder="Type Search"
                        showSearch
                        optionFilterProp="children"
                        onChange={(e: string) => setFilterType(e)}
                        defaultValue="name"
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        <Select.Option value="name">Name</Select.Option>
                        <Select.Option value="code">Code</Select.Option>
                    </Select>
                    <Search onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                    <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">Search</button>
                </Space>
            </div>
            {permissions && permissions["PROVIDERS-MANAGEMENT"] === "EDIT" && (
                <div className="row mb-md-3 align-items-md-center">
                    <div className="mb-3 mb-md-0">
                        <Button
                            type="primary"
                            className="d-block ms-auto"
                            icon={<i className="fa-solid fa-plus me-3"></i>}
                            onClick={toggleAdd}>
                            Add Provider
                        </Button>
                    </div>
                </div>
            )}
            <div className="box-white ant-box-table">
                <Table2
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    bordered={true}
                // totalCount={dataSource?.length || 0}
                // currentPage={pagination.page}
                // onChangePagination={setPagination}
                />
            </div>

            {isAddOpen && (
                <AddProviders
                    isOpen={isAddOpen}
                    close={toggleAdd}
                    onloadData={onloadData}
                />
            )}

            {isEditOpen && (
                <EditProviders
                    isOpen={isEditOpen}
                    close={toggleEdit}
                    onloadData={onloadData}
                    data={providerData}
                />
            )}

            {isViewOpen && (
                <ViewProviders
                    isOpen={isViewOpen}
                    close={toggleView}
                    data={providerData}
                    swapDataEdit={() => {
                        toggleView()
                        toggleEdit()
                    }}
                />
            )}
        </>
    );
};

export default Providers
