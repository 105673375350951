import React, { FC, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from "~contexts/ThemeContext";
import { ScreenContext } from "~contexts/ScreenContext";
import { Divider, Dropdown, Menu, Switch, Typography } from 'antd';
import type { MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components'
// import FormattedMessage from './FormattedMessage'
import { useThemeSwitcher } from "react-css-theme-switcher"
// import { useTranslation } from 'react-i18next'
// import { availableLanguages } from '~utils/i18n'
import ProfileModal from '~components/Users/Profile'
import ChangePasswordAccount from '~components/Users/ChangePasswordAccount'
import Modal2FASecurity from '~components/2fa/Modal'

const { Text } = Typography

interface ProfileButtonProps {
    className?: string
    username: string
    role?: string
}

type MenuItem = Required<MenuProps>['items'][number];

const ProfileButton: FC<ProfileButtonProps> = ({ className, username, role }) => {
    const { theme, toggleTheme } = useContext(ThemeContext)
    const { screen, toggleScreen } = useContext(ScreenContext)
    // const [visible, setVisible] = useState(false)

    // const onVisibleChange = (visible: boolean) => setVisible(visible)
    // const { i18n } = useTranslation()
    // const [current, setCurrent] = React.useState(i18n.language);
    const { switcher, themes } = useThemeSwitcher();

    const toggleTheme2 = (isChecked: boolean) => {
        switcher({ theme: isChecked ? themes.dark : themes.light });
    }


    const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
    const toggleModalProfile = () => setIsProfileModalOpen(!isProfileModalOpen)

    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState<boolean>(false);
    const toggleModalChangePassword = () => setIsChangePasswordOpen(!isChangePasswordOpen)

    const [is2FAOpen, setIs2FAOpen] = useState<boolean>(false);
    const toggleModal2FA = () => setIs2FAOpen(!is2FAOpen)

    const getItem = (
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem => {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    // const itemLang: any[] = Object.entries(availableLanguages).map((language: any) => {
    //     return getItem(
    //         <div onClick={() => { i18n.changeLanguage(language[0]); setCurrent(language[0]) }}>
    //             {language[1]}
    //         </div>, language[0])
    // })

    const items: MenuItem[] = [
        getItem(
            <div onClick={toggleModalProfile}>
                Profile
            </div>, "m-0"),
        getItem(
            <div onClick={toggleModalChangePassword}>
                Change Password
            </div>, "m-1"),
        getItem(
            <div onClick={toggleModal2FA}>
                2FA Security
            </div>, "m-2"),
        getItem(
            <div className="d-flex">
                <span>Mode</span>
                <Switch
                    className='ms-auto'
                    checkedChildren={<i className="fad fa-moon"></i>}
                    unCheckedChildren={<i className="fa fa-sun"></i>}
                    checked={theme === "dark" ? true : false}
                    onClick={(e) => {
                        toggleTheme(e)
                        toggleTheme2(e)
                    }}
                />
            </div>
            , 'm-3'),
        getItem(
            <div className="d-flex">
                <span>Full Screen</span>
                <Switch
                    className='ms-auto'
                    checkedChildren={"Open"}
                    unCheckedChildren={"Close"}
                    defaultChecked={screen === "full" ? true : false}
                    onClick={(e) => {
                        toggleScreen(e)
                    }}
                />
            </div>
            , 'm-4'),
        getItem(
            <Divider className="mt-2 mb-0" />
            , 'm-5'),
        getItem(
            <Link to="/logout">
                <Text type="danger">
                    <i className="fas fa-sign-out me-2"></i> Logout
                </Text>
            </Link>
            , 'm-6'),
    ];
    const menu: any = (
        <Menu className="profile-link-hover" items={items} />
    );
    return (
        <>
            <div className={"profile-button " + className}>
                <Dropdown overlay={menu} trigger={["click"]} placement="bottom" >
                    <Profile className="ant-dropdown-link">
                        <div className="d-lg-none">
                            <UserOutlined className="profile-icon" style={{ fontSize: '20px', padding: '4px 8px' }} />
                        </div>
                        <div className="profile-wrapper d-none d-lg-flex">
                            <div className="profile-char">
                                <span>{username?.charAt(0)}</span>
                            </div>
                            <div className="profile-info">
                                <div className="profile-username">{username}</div>
                                <small className="profile-permission">{role?.toLocaleUpperCase()}</small>
                            </div>
                        </div>
                        <i className="far fa-chevron-down profile-chevron-down"></i>
                    </Profile>
                </Dropdown>
            </div>

            {is2FAOpen &&
                <Modal2FASecurity
                    isOpen={is2FAOpen}
                    close={toggleModal2FA}
                    username={username}
                />
            }
            {isProfileModalOpen &&
                <ProfileModal
                    isOpen={isProfileModalOpen}
                    close={() => toggleModalProfile()}
                />
            }
            {isChangePasswordOpen &&
                <ChangePasswordAccount
                    isOpen={isChangePasswordOpen}
                    close={toggleModalChangePassword}
                    username={username}
                />
            }
        </>
    )
}

export default ProfileButton

const Profile = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.25;
`