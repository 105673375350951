import useSWR from 'swr'
import ServiceAPI from '~services/services'

export const useFetchTopgraphic = () => {
    const { data, mutate, error } = useSWR([`/topgraphic`], () => ServiceAPI.getTopgraphic());

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchTopgraphic
