import React, { FC, useState } from 'react'
import { Button, Tooltip } from 'antd'
import { useNavigate } from "react-router-dom"

interface EmergencyProps {
    username: string
}
const Emergency: FC<EmergencyProps> = ({ username }) => {
    let navigate = useNavigate();
    const [switchLoading, setSwitchLoading] = useState<boolean>(false)
    const logoutEmergency = async (username: string) => {
        setSwitchLoading(true)
        setTimeout(() => {
            localStorage.clear()
            navigate(`/logout?redirect=true`)
        }, 500);
    }
    return (
        <Tooltip title="If the emergency button is pressed, the account will be locked. If you want to unlock, you must contact the upper line only.">
            <Button type="primary" danger className="text-white" onClick={() => logoutEmergency(username)} loading={switchLoading}>
                <i className="fa-solid fa-light-emergency-on me-2"></i>
                Emergency
            </Button>
        </Tooltip>
    );
}

export default Emergency