import React, { FC } from 'react'
import { Table, Spin, Skeleton, Empty } from 'antd'
// import { LoadingOutlined } from '@ant-design/icons'
import { nanoid } from 'nanoid'
import ScrollToTopPagination from '~utils/ScrollToTopPagination'

interface TableProps {
    columns: any
    dataSource: any
    loading?: boolean
    title?: string
    footer?: string
    bordered?: boolean
    rowClassName?: (e: any) => void
}

const TableWithoutPagination: FC<TableProps> = ({ columns, dataSource, loading, bordered = false, title, footer, rowClassName }) => {

    let options: any = {}
    if (title) {
        options['title'] = () => title
    }
    if (footer) {
        options['footer'] = () => footer
    }

    return (
        <Spin spinning={loading} tip="Loading..." size="large" >
            <Table
                size={'small'}
                columns={columns as any}
                dataSource={dataSource}
                rowKey={() => nanoid()}
                // loading={loading ? {
                //     spinning: loading,
                //     indicator: <Spin tip="Loading..." size="large" />,
                // } : false}
                pagination={false}
                locale={{
                    emptyText: loading ? <Skeleton active={true} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }}
                bordered={bordered}
                scroll={{ x: true, y: true }}
                sticky={{ offsetHeader: window.screen.width > 992 ? 84 : 57 }}
                onChange={ScrollToTopPagination}
                rowClassName={rowClassName && rowClassName}
                {...options}
            />
        </Spin>
    )
}
export default TableWithoutPagination