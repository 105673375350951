import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Row,
    Space,
    Divider,
    Upload,
    Col,
    Select,
    Input,
    Checkbox
} from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import ServiceAPI from '~services/services'
import to from 'await-to-js'
import { IGamesUpdate } from '~types/game'
import { gameLobby, gameType } from '~utils/games'

interface EditProps {
    close: () => void
    onloadData: () => void
    isOpen: boolean
    data: any
    provider?: any
}

const EditGames: FC<EditProps> = ({ close, isOpen, onloadData, data, provider }) => {
    const [form] = Form.useForm();
    const title = "Edit Game"
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [imageUpload, setImageUpload] = useState<string>("")

    const updateData = async (values: IGamesUpdate) => {
        const [err, res] = await to(ServiceAPI.editGames(values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res.message)
            setBtnLoading(false)
            onloadData()
            close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: IGamesUpdate) => {
                setBtnLoading(true)
                if (!imageUpload) {
                    toast.error("Please upload image")
                    setBtnLoading(false)
                }
                values.id = data.id || ""
                values.active = data.active
                values.display = data.display
                values.image = imageUpload
                updateData(values)
            })
            .catch((errorInfo) => { });
    };


    const buttonUploadImage = (
        <div>
            {imageUpload ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload Image</div>
        </div>
    );


    const uploadImage = async (response: any) => {
        setBtnLoading(true)
        let formData: FormData = new FormData();
        formData.append('file', response.file);
        const [err, res] = await to(ServiceAPI.uploadImage(formData))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res?.message as string)
            setImageUpload(res?.link)
            setBtnLoading(false)
        }
    }

    const optionGameType = Object.keys(gameType).map((key: any) => {
        return <Select.Option key={key} value={key}>{gameType[key]}</Select.Option>
    })

    const optionProvider = (provider !== undefined && provider.length > 0) && Object.values(provider).map((item: any, key: any) => {
        return <Select.Option key={item.code} value={item.code}>{item.name}</Select.Option>
    })

    useEffect(() => {
        if (isOpen && form) {
            setImageUpload(data?.imageURL ? data?.imageURL : "")
            form.resetFields();
            form.setFieldsValue(data);

            form.setFieldsValue({ "gameType": data?.type, "provider": data?.providerCode })
        }
    }, [form, isOpen, data]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            Save
                        </Button>
                    </Space>
                }>
                <Form
                    form={form}
                    name="editGame"
                    layout="vertical">
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="provider"
                                label="Provider"
                                rules={[{ required: true, message: 'Please Select your Provider!' }]}
                                hasFeedback>
                                <Select
                                    placeholder="Select Provider"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {optionProvider}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="gameName"
                                label="Game Name"
                                rules={[{ required: true, message: 'Please input your Game Name!' }]}
                                hasFeedback>
                                <Input placeholder="Game Name" inputMode="text" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="gameCode"
                                label="Game Code"
                                rules={[{ required: true, message: 'Please input your Game Code!' }]}
                                hasFeedback>
                                <Input placeholder="Game Code" inputMode="text" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="gameType"
                                label="Game Type"
                                rules={[{ required: true, message: 'Please Select your Game Type!' }]}
                                hasFeedback
                            >
                                <Select
                                    placeholder="Select Game Type"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {optionGameType}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <label style={{ marginBottom: '16px' }}>Image</label>
                            <Upload
                                name="upload-image"
                                listType="picture-card"
                                showUploadList={false}
                                customRequest={(e) => uploadImage(e)}>
                                {imageUpload ? <img src={imageUpload} alt="ImageUpload" style={{ width: '100%' }} /> : buttonUploadImage}
                            </Upload>
                        </Col>
                        {Object.values(gameLobby).length > 0 && (
                            <>
                                <Divider />
                                <Col span={24}>
                                    <Form.Item name="lobby" label="Lobby">
                                        <Checkbox.Group>
                                            <Row>
                                                {Object.values(gameLobby).map((item: any, key: any) => (
                                                    <Col span={8}>
                                                        <Checkbox value={item} style={{ lineHeight: '32px' }}>
                                                            {item}
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> Save</Button>
                            <Button onClick={close} size="large">Close</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditGames

