import { IRoute } from '~types/IRoute'
import Dashboard from '~views/Dashboard'
import AnnouncerMember from '~views/AnnouncerMember'
import AnnouncerAgent from '~views/AnnouncerAgent'
import Providers from '~views/Management/Providers'
import ProvidersSettingPercentage from '~views/Management/SettingPercentage'
import Games from '~views/Management/Games'
import Topgraphics from '~views/Setting/Topgraphics'
import Popup from '~views/Setting/Popup'
import { SubAccounts, AddSubAccount, EditSubAccount } from '~views/SubAccounts'
import AccessLogs from '~views/SystemLogs/AccessLogs'

export const menuItems: Array<IRoute> = [
        {
                key: "dashboard",
                title: "Home",
                path: "/dashboard",
                content: Dashboard,
                hide: false,
                permission_key: '',
                permission_menu: ''
        },
        {
                key: "providers",
                title: "Providers",
                path: "/providers",
                content: Providers,
                hide: false,
                permission_key: 'PROVIDERS-MANAGEMENT',
                permission_menu: ''
        },
        {
                key: "providers-percentage",
                title: "Setting percentage",
                path: "/providers-percentage",
                content: ProvidersSettingPercentage,
                hide: false,
                permission_key: 'PERCENTAGE-MANAGEMENT',
                permission_menu: ''
        },
        {
                key: "games",
                title: "Games",
                path: "/games",
                content: Games,
                hide: false,
                permission_key: 'GAMES-MANAGEMENT',
                permission_menu: ''
        },
        // {
        //         key: "agents",
        //         title: "Agents",
        //         path: "/agents",
        //         content: Dashboard,
        //         hide: false,
        //         permission_key: '',
        //      permission_menu: ''
        // },
        // {
        //         key: "agents_add",
        //         title: "Add Agents",
        //         path: "/agents/add",
        //         content: AddAgent,
        //         hide: false,
        //         permission_key: 'USER-MANAGEMENT',
        // permission_menu: ''
        // },
        {
                key: "announcer-agent",
                title: "Announcer Agent",
                path: "/announcer-agent",
                content: AnnouncerAgent,
                hide: false,
                permission_key: 'ANNOUNCER-AGENT-MANAGEMENT',
                permission_menu: ''
        },
        {
                key: "announcer-member",
                title: "Announcer Member",
                path: "/announcer-member",
                content: AnnouncerMember,
                hide: false,
                permission_key: 'ANNOUNCERT-MEMBER-MANAGEMENT',
                permission_menu: ''
        },
        // {
        //         key: "maintenance-games",
        //         title: "maintenance-games",
        //         path: "/maintenance",
        //         content: Dashboard,
        //         hide: false,
        //         permission_key: '',
        // permission_menu: ''
        // },
        // {
        //         key: "setting",
        //         title: "Setting",
        //         path: "/setting",
        //         content: Dashboard,
        //         hide: false,
        //         permission_key: '',
        // permission_menu: ''
        // },
        {
                key: "topgraphics",
                title: "Topgraphics",
                path: "/topgraphics",
                content: Topgraphics,
                hide: false,
                permission_key: 'TOP-GRAPHICS-MANAGEMENT',
                permission_menu: ''
        },
        {
                key: "popup",
                title: "Popup",
                path: "/popup",
                content: Popup,
                hide: false,
                permission_key: 'POPUP-MANAGEMENT',
                permission_menu: ''
        },
        // {
        //         key: "lobby",
        //         title: "Lobby",
        //         path: "/lobby",
        //         content: Dashboard,
        //         hide: false,
        //         permission_key: '',
        // permission_menu: ''
        // },
        {
                key: "sub_accounts",
                title: "Sub Accounts",
                path: "/sub-accounts",
                content: SubAccounts,
                hide: false,
                permission_key: 'SUB-USER-MANAGEMENT',
                permission_menu: ''
        },
        {
                key: "sub_accounts_add",
                title: "Add Sub Account",
                path: "/sub-accounts/add",
                content: AddSubAccount,
                hide: false,
                permission_key: 'SUB-USER-MANAGEMENT',
                permission_menu: 'EDIT'
        },
        {
                key: "sub_accounts_edit",
                title: "Edit Sub Account",
                path: "/sub-accounts/edit/:username",
                content: EditSubAccount,
                hide: false,
                permission_key: 'SUB-USER-MANAGEMENT',
                permission_menu: 'EDIT'
        },
        {
                key: "access_logs",
                title: "Access Logs",
                path: "/access-logs",
                content: AccessLogs,
                hide: false,
                permission_key: 'ACCESS-LOGS',
                permission_menu: ''
        },
]
