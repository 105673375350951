import useSWR from 'swr'
import ServiceAPI from '~services/services'
// import { DateRangeType } from '~types/IDateRange'
import { QueryParams } from '~types/queryParams'

export const useFetchSubUsers = (params: QueryParams) => {
    const { data, mutate, error } = useSWR([`/sub-accounts?limit=${params.limit}&page=${params.page}`, params], () => ServiceAPI.getSubUserList(params));

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchSubUsers
