import { useState } from 'react'

const useDateRangeEmpty = () => {
	const [dateRange, setDateRange] = useState({
		start: "",
		end: "",
	})

	return {
		dateRange,
		setDateRange,
	}
}

export default useDateRangeEmpty
