import React, { FC } from 'react'
import {
    Drawer,
    Button,
    Space,
    Divider,
    Descriptions,
    Image
} from 'antd'
import ImageError from '~utils/ImageError'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { ApplicationState } from '~store/index'

interface ViewProps {
    close: () => void
    swapDataEdit: () => void
    isOpen: boolean
    data: any
}

const ViewTopgraphic: FC<ViewProps> = ({ close, isOpen, data, swapDataEdit }) => {

    const title = "View Topgraphic"

    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissions = authState.data.permissions

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        {permissions && permissions["TOP-GRAPHICS-MANAGEMENT"] === "EDIT" && (
                            <Button
                                type="primary"
                                className="btn btn-warning btn-sm text-white me-2"
                                onClick={swapDataEdit}
                            >
                                <i className="fa-solid fa-edit me-1"></i>
                                Edit</Button>
                        )}
                    </Space>
                }>
                <Descriptions title={"Topgraphic Information"} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                    <Descriptions.Item label="Topic">{data.topic}</Descriptions.Item>
                    <Descriptions.Item label="Description">{data.description}</Descriptions.Item>
                    <Descriptions.Item label="Thumbnail">
                        <Image
                            className='ant-img-circle'
                            width={220}
                            height={220}
                            alt={data.topic}
                            src={data.thumbnail ? data.thumbnail : 'error'}
                            fallback={ImageError}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Background">
                        <Image
                            className='ant-img-circle'
                            width={220}
                            height={220}
                            alt={data.topic}
                            src={data.background ? data.background : 'error'}
                            fallback={ImageError}
                        />
                    </Descriptions.Item>
                </Descriptions>
                <Divider />
                <Descriptions title="System Information" column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                    <Descriptions.Item label="CreatedAt">{moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}</Descriptions.Item>
                    <Descriptions.Item label="UpdatedAt">{moment(data.updatedAt).format("DD/MM/YYYY HH:mm:ss")}</Descriptions.Item>
                </Descriptions>
            </Drawer>
        </>
    )
}
export default ViewTopgraphic

