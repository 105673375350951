import { FC, useState, createContext } from 'react';

interface ScreenContextState {
  screen: string
  toggleScreen: (e: any) => void
}

const screenStorage = localStorage.getItem('fullScreen')

const defaultState = {
  screen: screenStorage || "default",
  // screen: screenStorage || "full",
  toggleScreen: () => { }
}

export const ScreenContext = createContext<ScreenContextState>(defaultState);


const ThemeProvider: FC = ({ children }) => {
  const [screen, setScreen] = useState(defaultState.screen)
  const changeScreen = () => localStorage.setItem('fullScreen', screen)

  const toggleScreen = (e: any) => {
    setScreen((e === true) ? "full" : "default")
    changeScreen()
  }

  changeScreen();

  return (
    <ScreenContext.Provider
      value={{
        screen,
        toggleScreen
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};

export default ThemeProvider;