import React, { FC, Fragment } from 'react'
import { Card, Row, Col, Empty, Image, Modal } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import ImageError from '~utils/ImageError'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import to from 'await-to-js'
import ServiceAPI from '~services/services'
import { ApplicationState } from '~store/index'
import { useSelector } from 'react-redux'
interface ListProps {
    dataSource: any
    loading?: boolean
    onLoadData: () => void
}

const CardPopup: FC<ListProps> = ({ dataSource, loading, onLoadData }) => {

    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissions = authState.data.permissions
    const { confirm } = Modal;
    const handleDelete = (key: string) => {
        confirm({
            title: `You want to delete your Popup?`,
            icon: <ExclamationCircleOutlined />,
            content: `Make sure you want to adjust your Popup`,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                (async () => {
                    const [err, res] = await to(ServiceAPI.deletePopup(key))
                    if (err) {
                        toast.error(err?.message as string)
                    } else {
                        onLoadData()
                        toast.success(res.message)
                    }
                })();
            },
            onCancel() {

            },
        });
    }


    const listCard = dataSource.length !== 0 ? dataSource.map((item: any, idx: any) => {

        return (
            <Col md={12} xl={8} offset={0.5} key={`col-${item.key}`}>
                {permissions && permissions["POPUP-MANAGEMENT"] === "EDIT" ? (
                    <Card
                        key={item.key}
                        className="box-white box-promotion"
                        bordered={false}
                        cover={
                            <Image
                                alt={item.key}
                                src={item.thumbnail ? item.thumbnail : 'error'}
                                fallback={ImageError}
                            />
                        }
                        actions={[
                            <DeleteOutlined key="delete" style={{ color: 'var(--text-primary)' }} onClick={() => handleDelete(item.key)} />,
                        ]}
                        loading={loading}
                    >
                    </Card>
                ) : (
                    <Card
                        key={item.key}
                        className="box-white box-promotion"
                        bordered={false}
                        cover={
                            <Image
                                alt={item.key}
                                src={item.thumbnail ? item.thumbnail : 'error'}
                                fallback={ImageError}
                            />
                        }
                        loading={loading}
                    >
                    </Card>
                )}
            </Col>
        )
    }) : (<div style={{ alignContent: 'center', width: '100%', padding: '40px 0 40px 0' }}><Empty /></div>)

    return (
        <Fragment>
            <Row gutter={[16, 24]}>
                {listCard}
            </Row>
        </Fragment>
    )
}

export default CardPopup