import { ISidebar } from '~types/ISidebar'
// import i18n from "~utils/i18n"

const onLoadMenuSidebar = () => {
    const menuSidebar: Array<ISidebar> = [
        {
            id: 'dashboard',
            permission_key: '',
            title: "Dashboard",
            path: "/dashboard",
            icon: "fa-duotone fa-house-chimney",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'sub_accounts',
            permission_key: 'SUB-USER-MANAGEMENT',
            title: "Sub Accounts",
            path: "/sub-accounts",
            icon: "fa-duotone fa-users",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'provider-management',
            permission_key: '',
            title: "Providers Management",
            path: "#",
            icon: "fa-duotone fa-chess-king-piece",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'provider',
            permission_key: 'PROVIDERS-MANAGEMENT',
            title: "Providers",
            path: "/providers",
            icon: "",
            isShow: true,
            parent_id: 'provider-management'
        },
        {
            id: 'provider-percentage',
            permission_key: 'PERCENTAGE-MANAGEMENT',
            title: "Setting Percentage",
            path: "/providers-percentage",
            icon: "",
            isShow: true,
            parent_id: 'provider-management'
        },
        {
            id: 'games-management',
            permission_key: 'GAMES-MANAGEMENT',
            title: "Games Management",
            path: "/games",
            icon: "fa-duotone fa-cards",
            isShow: true,
            parent_id: '0'
        },
        // {
        //     id: 'agent-management',
        //     permission_key: '',
        //     title: "Agents Management",
        //     path: "/Agents",
        //     icon: "fa-duotone fa-users",
        //     isShow: true,
        //     parent_id: '0'
        // },
        {
            id: 'announcer',
            permission_key: '',
            title: "Announcer",
            path: "#",
            icon: "fa-duotone fa-megaphone",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'announcer-agent',
            permission_key: 'ANNOUNCER-AGENT-MANAGEMENT',
            title: "Agent",
            path: "/announcer-agent",
            icon: "",
            isShow: true,
            parent_id: 'announcer'
        },
        {
            id: 'announcer-member',
            permission_key: 'ANNOUNCERT-MEMBER-MANAGEMENT',
            title: "Member",
            path: "/announcer-member",
            icon: "",
            isShow: true,
            parent_id: 'announcer'
        },
        {
            id: 'menu-setting',
            permission_key: '',
            title: "Setting",
            path: "#",
            icon: "fa-duotone fa-screwdriver-wrench",
            isShow: true,
            parent_id: '0'
        },
        // {
        //     id: 'menu-setting-lobby',
        //     permission_key: '',
        //     title: "Lobby",
        //     path: "/lobby",
        //     icon: "",
        //     isShow: true,
        //     parent_id: 'menu-setting'
        // },
        // {
        //     id: 'menu-setting-maintenance',
        //     permission_key: '',
        //     title: "Maintenance",
        //     path: "/maintenance",
        //     icon: "",
        //     isShow: true,
        //     parent_id: 'menu-setting'
        // },
        {
            id: 'menu-setting-topgraphics',
            permission_key: 'TOP-GRAPHICS-MANAGEMENT',
            title: "Topgraphics",
            path: "/topgraphics",
            icon: "",
            isShow: true,
            parent_id: 'menu-setting'
        },
        {
            id: 'menu-setting-popup',
            permission_key: 'POPUP-MANAGEMENT',
            title: "Popup",
            path: "/popup",
            icon: "",
            isShow: true,
            parent_id: 'menu-setting'
        },
        {
            id: 'system-log',
            permission_key: '',
            title: "System logs",
            path: "#",
            icon: "fa-duotone fa-history",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'system-log-access-logs',
            permission_key: 'ACCESS-LOGS',
            title: "Access Logs",
            path: "/access-logs",
            icon: "",
            isShow: true,
            parent_id: 'system-log'
        },
        // {
        //     id: 'system-log-payment-logs',
        //     permission_key: '',
        //     title: "Payment Logs",
        //     path: "/payment-logs",
        //     icon: "",
        //     isShow: true,
        //     parent_id: 'system-log'
        // },
    ]

    const listMenuSidebar: any = menuSidebar?.reduce((item: any, menu: any) => {
        if (menu.parent_id === '0') {
            item[menu.id] = menu
        } else {
            if (typeof item[menu.parent_id]['submenu'] === 'undefined') {
                item[menu.parent_id]['submenu'] = {}
            }
            item[menu.parent_id]['submenu'][menu.id] = menu
        }
        return item;
    }, {})

    return listMenuSidebar
}


// // i18next seems ready -> initial fill translations
// // if (i18n.isInitialized) {
// //     onLoadMenuSidebar();
// // }

// //   // reset translations to new values on language change
// i18n.on('languageChanged', function (lng) {
//     onLoadMenuSidebar();
// });

export default onLoadMenuSidebar


