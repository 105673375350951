import React, { FC } from 'react'
import {
    Drawer,
    Button,
    Space,
    Divider,
    Descriptions,
    Image
} from 'antd'
import ImageError from '~utils/ImageError'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { ApplicationState } from '~store/index'

interface ViewProps {
    close: () => void
    swapDataEdit: () => void
    isOpen: boolean
    data: any
}

const ViewProviders: FC<ViewProps> = ({ close, isOpen, data, swapDataEdit }) => {

    const title = "View Provider"

    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissions = authState.data.permissions

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        {permissions && permissions["PROVIDERS-MANAGEMENT"] === "EDIT" && (
                            <Button
                                type="primary"
                                className="btn btn-warning btn-sm text-white me-2"
                                onClick={swapDataEdit}
                            >
                                <i className="fa-solid fa-edit me-1"></i>
                                Edit</Button>
                        )}
                    </Space>
                }>
                <Descriptions title={"Provider Information"} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                    <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
                    <Descriptions.Item label="Code">{data.code}</Descriptions.Item>
                    <Descriptions.Item label="Type">{data.type || "-"}</Descriptions.Item>
                    <Descriptions.Item label="Logo">
                        <Image
                            className='ant-img-circle'
                            width={220}
                            height={220}
                            alt={data.name}
                            src={data.imageURL ? data.imageURL : 'error'}
                            fallback={ImageError}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Background Image">
                        <Image
                            className='ant-img-circle'
                            width={220}
                            height={220}
                            alt={data.name}
                            src={data.backgroundImageURL ? data.backgroundImageURL : 'error'}
                            fallback={ImageError}
                        />
                    </Descriptions.Item>
                </Descriptions>
                <Divider />
                <Descriptions title="System Information" column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                    <Descriptions.Item label="Status">
                        {data.status
                            ? <div className="msn-badge badge-success ms-2">Open</div>
                            : <div className="msn-badge badge-danger ms-2">Close</div>}
                    </Descriptions.Item>
                    <Descriptions.Item label="CreatedAt">{moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}</Descriptions.Item>
                    <Descriptions.Item label="UpdatedAt">{moment(data.updatedAt).format("DD/MM/YYYY HH:mm:ss")}</Descriptions.Item>
                    <Descriptions.Item label="CreatedBy">{data.createdBy}</Descriptions.Item>
                </Descriptions>
            </Drawer>
        </>
    )
}
export default ViewProviders

