import React, { useEffect, useState } from 'react'
import { Space, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import Button from 'antd-button-color'
import { toast } from 'react-toastify'
import to from 'await-to-js'
import TitleBar from '~components/Elements/TitleBar'
// import FormattedMessage from '~components/Elements/FormattedMessage'
// import TableWithoutPagination from '~components/Elements/TableWithoutPagination'
import NumberWithCommas from '~utils/NumberWithCommas'
import useFetchAnnouncerMember from '~hooks/useFetchAnnouncerMember'
import ServiceAPI from '~services/services'
import AddAnnouncer from '~components/Announcer/Member/Add'
import moment from 'moment'
import TableMiniComponent from '~components/Elements/TableMini'
import { ApplicationState } from '~store/index'
import { useSelector } from 'react-redux'

const AnnouncerMember = () => {

    const routes = [
        {
            path: '',
            breadcrumbName: 'Announcer Member',
        },
    ];

    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissions = authState.data.permissions
    const { confirm } = Modal;
    const [dataSource, setDataSource] = useState<any>([])
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const toggleAdd = () => setIsAddOpen(!isAddOpen)

    const { data, mutate, isLoading } = useFetchAnnouncerMember()

    const handleDelete = (id: string) => {
        confirm({
            title: `You want to delete your Announcer?`,
            icon: <ExclamationCircleOutlined />,
            content: `Make sure you want to adjust your Announcer`,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                (async () => {
                    const [err, res] = await to(ServiceAPI.deleteAnnouncerMember(id))
                    if (err) {
                        toast.error(err?.message as string)
                    } else {
                        mutate()
                        toast.success(res.message)
                    }
                })();
            },
            onCancel() {

            },
        });
    }

    const columns = [
        {
            title: 'Message',
            dataIndex: 'announcer',
            render: (text: any, record: any) => (
                <div>{(record.description)}</div>
            )
        },
        {
            title: 'CreatedBy',
            dataIndex: 'createdBy',
            render: (text: any, record: any) => (
                <div>{(record.createdBy)}</div>
            )
        },
        {
            title: 'ExpiredAt',
            dataIndex: 'expiredAt',
            ellipsis: true,
            width: '140',
            align: 'center',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.expiredAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.expiredAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'UpdatedAt',
            dataIndex: 'updatedAt',
            ellipsis: true,
            width: '140',
            align: 'center',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.updatedAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.updatedAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            ellipsis: true,
            width: 140,
            align: 'center',
            hidden: permissions && (permissions["ANNOUNCERT-MEMBER-MANAGEMENT"] === "VIEW" || permissions["ANNOUNCERT-MEMBER-MANAGEMENT"] === "OFF"),
            render: (text: any, record: any) => (
                <Space size={0}>
                    <Button
                        type="danger"
                        size="small"
                        icon={<i className="fa-solid fa-trash me-2"></i>}
                        onClick={() => { handleDelete(record.id) }}>
                        Delete
                    </Button>
                </Space >
            )
        }
    ].filter(item => !item.hidden);

    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.data.length > 0) {
            setDataSource(data.data)
        }
        return () => {
            setDataSource([])
        }
    }, [data, isLoading, setDataSource])

    return (
        <>
            <TitleBar title="Announcer Member" subTitle={`${NumberWithCommas(dataSource.length || 0)} items`} routes={routes} />

            {permissions && permissions["ANNOUNCERT-MEMBER-MANAGEMENT"] === "EDIT" && (
                <div className="row mb-md-3 align-items-md-center">
                    <div className="mb-3 mb-md-0">
                        <Button
                            type="primary"
                            className="d-block ms-auto"
                            icon={<i className="fa-solid fa-plus me-3"></i>}
                            onClick={toggleAdd}>
                            Add Announcer
                        </Button>
                    </div>
                </div>
            )}
            <div className="box-white ant-box-table">
                <TableMiniComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                />
            </div>
            {isAddOpen && (
                <AddAnnouncer
                    isOpen={isAddOpen}
                    close={toggleAdd}
                    onloadData={mutate}
                />
            )}
        </>
    )
}

export default AnnouncerMember