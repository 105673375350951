import { useEffect, useState } from 'react'
import TitleBar from '~components/Elements/TitleBar'
import FormattedMessage from '~components/Elements/FormattedMessage'
import { Row, Col, Typography, Tabs, Descriptions, Empty } from 'antd' //Statistic, Card, Table, 
import { ApplicationProviderState, ApplicationState } from '~store/index'
import { useSelector } from 'react-redux'
import { getProvider, getGamesType } from '~utils/games'

const Dashboard = () => {

    const authState = useSelector((state: ApplicationState) => state.auth)
    const { providers: providerState } = useSelector((state: ApplicationProviderState) => state.providers)
    const products: any = authState.data.user?.products
    const [dataProducts, setDataProducts] = useState<any>([])

    const TabTypeProducts = Object.keys(getGamesType).map((item: string, index: any) => {
        const productList: any = dataProducts.filter((product: any) => product.type === item.toLocaleUpperCase())
        const productType = (Object.values(getGamesType)[index]).toLocaleUpperCase()

        const productElement = productList && productList.length > 0 ? productList.map((product: any) => {
            if (!product.enabled) {
                return ""
            }
            return (
                <Descriptions.Item label={product.name} key={product.key}>
                    {product.ownPT}%
                </Descriptions.Item>
            )
        }) : "";
        return {
            label: productType,
            key: index,
            children: (
                (productList && productList.length > 0) ? (
                    <Descriptions column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }} bordered size="small">
                        <Descriptions.Item label={<span style={{ fontWeight: "bold" }}>Game Name</span>} key={0}>
                            <span style={{ fontWeight: "bold" }}>Percentage</span>
                        </Descriptions.Item>
                        {productElement}
                    </Descriptions>
                ) : <Empty key={`Empty ${productType}`} />
            ),
            disabled: productList && productList.length > 0 ? false : true
        }
    })


    useEffect(() => {
        setDataProducts([])
        if (products !== undefined && Object.keys(products).length > 0 && providerState !== undefined && Object.keys(providerState.data).length > 0) {
            const productIn = Object.keys(products).map((key: string) => {
                const provider = getProvider(key, providerState.data)
                return {
                    "key": key,
                    "name": provider.name,
                    "type": provider.type,
                    "ownPT": products[key].ownPT,
                    "enabled": products[key].enabled,
                }
            })
            setDataProducts(productIn);
        }
    }, [products, providerState])

    return (
        <>
            <TitleBar title={<FormattedMessage id={'main.dashboard'} defaultMessage={'Home'} />} subTitle={""} />
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} lg={24} className="mt-3 mt-xl-0">
                    <Typography.Title level={5}>Providers Percentage</Typography.Title>
                    <Tabs defaultActiveKey="0" centered items={TabTypeProducts} tabPosition={"top"} />
                </Col>
            </Row>
        </>
    );
};

export default Dashboard