import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import en from "~locales/en/translations.json"
// import th from "~locales/th/translations.json"
// import cn from "~locales/th/translations.json"
// import la from "~locales/th/translations.json"
// import id from "~locales/th/translations.json"

const resources = {
  en,
  // th,
  // cn,
  // la,
  // id
}

const LanguagesSelect = {
  'en': 'English',
  // 'th': 'ภาษาไทย',
  // 'cn': '简体中文',
  // 'la': 'ພາສາລາວ',
  // 'id': 'Bahasa Indonesia',
}

export const availableLanguages = LanguagesSelect

const langDefault = localStorage.getItem('i18nextLng') || 'en'

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    lng: langDefault,
    defaultNS: 'translations',
  });

export default i18n