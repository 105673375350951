import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Row,
    Space,
    Divider,
    Upload,
    Col,
    Input,
    Select,
} from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import ServiceAPI from '~services/services'
import to from 'await-to-js'
import { IProviderUpdate } from '~types/provider'
import { ProviderType } from '~utils/games'

interface EditProps {
    close: () => void
    onloadData: () => void
    isOpen: boolean
    data: any
}

const EditProviders: FC<EditProps> = ({ close, isOpen, onloadData, data }) => {
    const [form] = Form.useForm();
    const title = "Edit Provider"
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [imageUpload, setImageUpload] = useState<string>("")
    const [imageBackground, setImageBackground] = useState<string>("")

    const updateData = async (values: IProviderUpdate) => {
        const [err, res] = await to(ServiceAPI.editProviders(values, data.id))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res.message)
            setBtnLoading(false)
            onloadData()
            close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: IProviderUpdate) => {
                setBtnLoading(true)
                if (!imageUpload || !imageBackground) {
                    toast.error("Please upload image")
                    setBtnLoading(false)
                }
                values.imageURL = imageUpload
                values.backgroundImageURL = imageBackground
                updateData(values)
            })
            .catch((errorInfo) => { });
    };


    const buttonUploadImage = (
        <div>
            {imageUpload ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload Logo</div>
        </div>
    );

    const buttonUploadBackground = (
        <div>
            {imageBackground ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload Background</div>
        </div>
    );


    const uploadImage = async (response: any, type: string) => {
        setBtnLoading(true)
        let formData: FormData = new FormData();
        formData.append('file', response.file);
        if (type === "image") {
            const [err, res] = await to(ServiceAPI.uploadImage(formData))
            if (err) {
                toast.error(err?.message as string)
                setBtnLoading(false)
            } else {
                toast.success(res?.message as string)
                setImageUpload(res?.link)
                setBtnLoading(false)
            }
        } else if (type === "background") {
            const [err, res] = await to(ServiceAPI.uploadImage(formData))
            if (err) {
                toast.error(err?.message as string)
                setBtnLoading(false)
            } else {
                toast.success(res?.message as string)
                setImageBackground(res?.link)
                setBtnLoading(false)
            }
        } else {
            return false
        }
    }

    const optionGameType = Object.keys(ProviderType).map((key: any) => {
        return <Select.Option key={key} value={key}>{Object.values(ProviderType)[key]}</Select.Option>
    })

    useEffect(() => {
        if (isOpen && form) {
            setImageUpload(data?.imageURL ? data?.imageURL : "")
            setImageBackground(data?.backgroundImageURL ? data?.backgroundImageURL : "")
            form.resetFields();
            form.setFieldsValue(data);
        }
    }, [form, isOpen, data]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                open={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            Save
                        </Button>
                    </Space>
                }>
                <Form
                    form={form}
                    name="editGame"
                    layout="vertical">
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please input your Name!' }]}
                                hasFeedback>
                                <Input placeholder="Name" inputMode="text" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="type"
                                label="Type"
                                rules={[{ required: true, message: 'Please input your Type!' }]}
                                hasFeedback>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="Select Type">
                                    {optionGameType}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Row>
                            <Col span={12}>
                                <label style={{ marginBottom: '16px' }}>Logo</label>
                                <Upload
                                    name="upload-image"
                                    listType="picture-card"
                                    showUploadList={false}
                                    customRequest={(e) => uploadImage(e, 'image')}>
                                    {imageUpload ? <img src={imageUpload} alt="ImageUpload" style={{ width: '100%' }} /> : buttonUploadImage}
                                </Upload>
                            </Col>
                            <Col span={12}>
                                <label style={{ marginBottom: '16px' }}>Background Image</label>
                                <Upload
                                    name="background-image"
                                    listType="picture-card"
                                    showUploadList={false}
                                    customRequest={(e) => uploadImage(e, 'background')}>
                                    {imageBackground ? <img src={imageBackground} alt="imageBackground" style={{ width: '100%' }} /> : buttonUploadBackground}
                                </Upload>
                            </Col>
                        </Row>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> Save</Button>
                            <Button onClick={close} size="large">Close</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditProviders

