import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import {
    Form,
    Input,
    Row,
    Col,
    PageHeader,
    Divider,
    Space,
    Button,
    Typography,
    Radio
} from 'antd'
import to from 'await-to-js'
import {
    SubUserAdd
} from '~types/users'
import { ThunkDispatch } from '~types/store'
import TitleBar from '~components/Elements/TitleBar'
import { ApplicationState } from '~store/index'
import { requestAuthInit } from '~store/auth/auth.actions'
import { permissions, permissionRadioOption } from '~utils/permissions'
import ServiceAPI from '~services/services'

const AddAccount = () => {
    const [form] = Form.useForm();
    let navigate = useNavigate();
    const routes = [
        {
            path: '/sub-accounts',
            breadcrumbName: 'Sub Accounts',
        },
        {
            path: '',
            breadcrumbName: 'Add Sub Account',
        },
    ];

    const authState = useSelector((state: ApplicationState) => state.auth)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [prefixName, setPrefixName] = useState<string>("")

    const insertData = async (values: SubUserAdd) => {
        const [err, res] = await to(ServiceAPI.addSubUser(values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
            navigate(`/sub-accounts`)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: SubUserAdd) => {
                setBtnLoading(true)
                delete values.confirm_password
                insertData(values)
            })
            .catch((errorInfo) => {

            });
    };

    const listPermissions = permissions && Object.keys(permissions).map((item: any) => {
        const lable = permissions[item].name
        return (
            <Col xs={24} sm={12} key={`col-permission-${item}`}>
                <Form.Item
                    label={lable}
                    name={['permissions', item]}
                    key={item}
                    rules={[{ required: true, message: 'Please pick an permission!' }]}>
                    <Radio.Group
                        options={permissionRadioOption}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </Form.Item>
            </Col>
        )
    })

    useEffect(() => {
        if (form) {
            form.resetFields();
            setPrefixName(authState.data.username)
            permissions && Object.keys(permissions).forEach((item: any) => {
                form.setFieldsValue({
                    'permissions': {
                        [`${item}`]: permissionRadioOption[0].value
                    }
                })
            })
        }
    }, [form, authState])


    return (
        <Fragment>
            <TitleBar title="Sub Accounts" routes={routes} />
            <PageHeader
                className="site-page-header"
                onBack={() => navigate(`/sub-accounts`)}
                title="Add Sub Account"
            />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="addSubAccounts"
                    layout="vertical">
                    <Typography.Title level={4} style={{ marginBottom: 16 }}>Sub Account Information</Typography.Title>
                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="username"
                                label="Username"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                                hasFeedback>
                                <div className="d-flex">
                                    <Input placeholder="Username" prefix={`${prefixName}@`} inputMode="text" autoComplete="new-username" />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please input your name!' }]}
                                hasFeedback>
                                <Input placeholder="Name" inputMode="text" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                                hasFeedback>
                                <Input.Password placeholder="Password" inputMode="text" autoComplete="new-password" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="confirm_password"
                                label="Confirm Password"
                                dependencies={['password']}
                                rules={[
                                    { required: true, message: 'Please confirm your password!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    })
                                ]}
                                hasFeedback>
                                <Input.Password placeholder="Confirm Password" inputMode="text" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="phone"
                                label="Phone Number"
                                rules={[{ required: false, message: 'Please input your phone number!' }]}
                            >
                                <Input placeholder="Phone Number" inputMode="tel" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Typography.Title level={4} style={{ marginBottom: 16 }}>Permissions</Typography.Title>
                    <Row gutter={16}>
                        {listPermissions}
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large" className="text-white me-2"><i className="fa-solid fa-save me-2"></i>Save</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default AddAccount